var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "amap-page-container" },
    [
      _c(
        "el-amap",
        {
          staticClass: "amap-demo",
          attrs: { vid: "amapDemo", zoom: _vm.zoom, center: _vm.center },
        },
        [
          _c("el-amap-marker", {
            attrs: {
              vid: "component-marker",
              position: _vm.componentMarker.position,
              "content-render": _vm.componentMarker.contentRender,
            },
          }),
          _vm._l(_vm.markers, function (marker, index) {
            return _c("el-amap-marker", {
              key: index,
              attrs: {
                position: marker.position,
                events: marker.events,
                visible: marker.visible,
                draggable: marker.draggable,
                vid: index,
              },
            })
          }),
        ],
        2
      ),
      _c("div", { staticClass: "toolbar" }, [
        _c(
          "button",
          {
            attrs: { type: "button", name: "button" },
            on: { click: _vm.toggleVisible },
          },
          [_vm._v(" toggle first marker ")]
        ),
        _c(
          "button",
          {
            attrs: { type: "button", name: "button" },
            on: { click: _vm.changePosition },
          },
          [_vm._v(" change position ")]
        ),
        _c(
          "button",
          {
            attrs: { type: "button", name: "button" },
            on: { click: _vm.chnageDraggle },
          },
          [_vm._v(" change draggle ")]
        ),
        _c(
          "button",
          {
            attrs: { type: "button", name: "button" },
            on: { click: _vm.addMarker },
          },
          [_vm._v(" add marker ")]
        ),
        _c(
          "button",
          {
            attrs: { type: "button", name: "button" },
            on: { click: _vm.removeMarker },
          },
          [_vm._v(" remove marker ")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }