<!--
 * @Author: Do not edit
 * @Date: 2020-07-22 10:34:01
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-09 10:49:47
 * @Description: Do not edit
 * @FilePath: \user\src\views\businessTravel\hotel\map-query.vue
-->
<template>
  <div class="map-query-container">
    <div class="title-area">
      <div class="icon" @click="goBack">
        <en-icon name="iconfanhui" size="20px"></en-icon>
      </div>
      <div class="title">
        地图查询
      </div>
    </div>
    <div class="content-area">
      <mapComp></mapComp>
    </div>
  </div>
</template>
<script>
import mapComp from "./components/mapComponents";
// import hotelDetailMin from "./components/hotel-detail-min";
export default {
  name: "MapQuery",
  components: { mapComp },
  props: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    goBack() {
      this.$router.go(-1);
    }
  }
};
</script>
<style scoped lang="scss">
.map-query-container {
  height: 100%;
  width: 100%;
  min-width: 1000px;
  overflow: hidden;
  background: rgba(255, 255, 255, 1) !important;
  .title-area {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 48px;
    border-bottom: 1px solid rgba(232, 236, 242, 1);
    .title {
      line-height: 48px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      text-align: right;
    }
    .icon {
      // font-size: 20px;
      color: #a9b5c6;
      margin-right:8px;
      cursor: pointer;
      // width: 24px;
      // height: 24px;
      overflow: hidden;
      // transform: rotate(-90deg);
    }
  }
  .content-area {
    height: calc(100% - 48px);
    .amap-wrapper {
      height: 100%;
      position: relative;
      background-color: #fff;
    }
  }
}
</style>
