<template>
  <div ref="chooseItem" class="chooseItem-container" :class="{ visible }">
    <el-popover
      v-model="visible"
      placement="bottom"
      popper-class="chooseItem-card"
      :width="width"
      :visible-arrow="false"
      trigger="click"
    >
      <div class="item-container" :style="{ width }">
        <div
          v-for="option in options"
          :key="option.value"
          class="option"
          :class="{ active: value.find(item => item.value === option.value) }"
          @click.stop="choose(option)"
        >
          {{ option.label }}
        </div>
      </div>
      <div slot="reference" class="chooseItem-title" :class="{ visible }">
        {{ name }}
        <i class="el-icon-arrow-down" :class="{ visible }"></i>
      </div>
    </el-popover>
  </div>
</template>
<script>
// import deepcopy from "deepcopy";
import Clickoutside from "element-ui/src/utils/clickoutside";

export default {
  name: "ChooseItem",
  components: {},
  directives: { Clickoutside },
  props: {
    name: {
      type: String,
      default: "名称"
    },
    value: { type: Array },
    options: {
      type: Array,
      default: () => [
        { label: "我是选项1", value: 1 },
        { label: "我是选项2", value: 1 }
      ]
    }
  },
  data() {
    return { width: 200, visible: false };
  },
  computed: {},
  watch: {
    // value(x) {
    //   console.log(x);
    // }
  },
  mounted() {
    this.initPopWidth();
  },
  methods: {
    choose(value) {
      const copyData = this.value;
      const index = copyData.findIndex((item) => value.value === item.value);
      if (index >= 0) {
        copyData.splice(index, 1);
        this.$emit("input", copyData);
      } else {
        copyData.push(value);
        this.$emit("input", copyData);
      }
    },
    toggle() {
      this.visible = !this.visible;
    },
    handleClose() {
      this.visible = false;
    },
    initPopWidth() {
      this.$nextTick(() => {
        if (this.$refs.chooseItem) {
          this.width = this.$refs.chooseItem.parentNode.clientWidth;
        }
      });
    }
  }
};
</script>
<style scoped lang="scss">
.chooseItem-container {
  position: relative;
  -webkit-user-select: none;
  outline: none;
  .chooseItem-title:focus {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  .visible {
    color: #3e90fe;
  }

  // &.visible::before {
  //   transition: all 0.1s ease;
  //   transform: rotate(45deg);
  //   background: #ffffff;
  //   border: solid 1px #ececec;
  //   position: absolute;
  //   content: "";
  //   width: 10px;
  //   height: 10px;
  //   pointer-events: auto;
  //   top: 30px;
  //   left: calc(50% - 10px);
  //   // z-index: -21;
  // }
  .el-icon-arrow-down {
    transform: scaleX(0.9);
    transition: transform 0.3s;
    &.visible {
      transform: rotateZ(180deg);
    }
  }
}
.item-container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  max-height: 400px;
  overflow-y: auto;
  .option {
    -webkit-user-select: none;
    height: 24px;
    line-height: 24px;
    padding: 0 8px;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #636c78;
    margin-right: 20px;
    margin-bottom: 5px;
    margin-top: 5px;
    cursor: pointer;
    border-radius: 4px;
    width: auto;
    &.active {
      background: #3e90fe;
      color: white;
    }
  }
}
</style>
<style lang="scss">
.el-popover.el-popper.chooseItem-card {
  left: 302px !important;
}
</style>
