<template>
  <div class="hotel-booking-container">
    <!-- <popForAddPeople ref="dialogForAddPeople"></popForAddPeople> -->
    <!-- <travelRelativeDialog ref="travelRelativeDialog"></travelRelativeDialog> -->
    <en-itinerary
      v-if="tripShow"
      :dialog-visible.sync="tripShow"
      :is-new-node="dataSource.tripId ? false:true"
      @selectTripData="selectTripData"
      @selectUnrelatedTrip="selectUnrelatedTrip"
      @selectCreateNewTrip="selectCreateNewTrip"
    ></en-itinerary>

    <en-contacts
      v-if="contactShow"
      :dialog-visible.sync="contactShow"
      :exist-list="existList"
      @getEnContactConfirm="getEnContactConfirm"
    ></en-contacts>

    <en-tips-dialog
      :dialog-visible.sync="dialogTipsVisible"
      title="温馨提醒:"
      :message="message"
      @getEnTipsDialogConfirm="confirmSubmitTripNodList"
    />

    <overStandardDialog
      v-if="overStandardVisible"
      :dialog-visible.sync="overStandardVisible"
      @getOverStandardMemo="getOverStandardMemo"
    ></overStandardDialog>

    <div class="header-contain">
      <div class="click-title">
        <div class="left-contain" @click="$router.go(-1)">
          <en-icon
            name="fanhui"
            size="14px;"
            :style="{ fill: '#A9B5C6' }"
            class="back-icon"
          />
          <span class="title">酒店预订</span>
        </div>
      </div>
    </div>

    <div class="booking-container">
      <div class="booking-board">
        <div class="content">
          <div class="hotel-detail-padding-container">
            <div class="hotel-detail">
              <en-image
                class="hotel-img"
                :src="pics[0]"
                lazy
                fit="cover"
                :preview-src-list="pics"
              ></en-image>
              <div class="detail-info">
                <div class="hotel-name">
                  {{ hotelName }}
                </div>
                <div class="hotel-label">
                  {{ categoryName }}
                </div>
                <div class="hotel-location">
                  {{ address }}
                </div>
              </div>
            </div>
            <div class="time-detail">
              <div class="start-time">
                <div class="desc">
                  入住时间
                </div>
                <div class="time">
                  {{ arrivalDate }}
                </div>
              </div>
              <div class="total-time">
                <div class="title">共{{ totalDay }}晚</div>
                <en-icon class="icon" name="jiantou-jipiao"></en-icon>
              </div>
              <div class="end-time">
                <div class="desc">
                  离店时间
                </div>
                <div class="time">
                  {{ departureDate }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="left-area">
            <i
              class="el-icon-warning"
              style="color:#F7BF27;font-size:16px;vertical-align:-1px"
            ></i>
            温馨提示：该订单确认后不可取消或更改，若未入住将收取您全额房费
          </div>
          <div class="right-area">
            <el-popover
              popper-class="custom-hotel-book-popover"
              placement="bottom-end"
              trigger="hover"
            >
              <div class="amount-hotel-book-popover">
                {{ numOfRoom }}间客房 X {{ totalDay }}晚
                {{ breakfastType }}&nbsp;&nbsp;&nbsp;&nbsp;￥{{ totalPrice }}
              </div>
              <div slot="reference">
                订单总额: <span style="color:#F88529">￥</span
                ><span style="color:#F88529;font-size:16px">{{
                  totalPrice
                }}</span>
              </div>
            </el-popover>
          </div>
        </div>
      </div>

      <!-- 中间黑色8px圆角分隔 -->
      <div class="seaprator-contain">
        <div class="seaprator-top"></div>
        <div class="seaprator-bottom"></div>
      </div>

      <div class="booking-info">
        <div class="header">
          入住信息
        </div>
        <div class="content">
          <div class="line-select">
            <div class="left">
              房间数量
            </div>
            <div class="right">
              <en-select
                v-model="numOfRoom"
                class="select-room-num"
                style="width:420px"
                :data="options"
              >
              </en-select>
            </div>
          </div>
          <div class="line-select">
            <div class="left">
              个人信息
            </div>
            <div class="right">
              <personCard
                v-for="item in personList"
                :key="item.key"
                :name="item.name"
                :phone-num="item.mobile"
                :id-card-num="item.idNumber"
                class="item"
                @deletePersonCard="deletePersonCard(item)"
              ></personCard>

              <personCard
                v-if="personList.length < 3 && personList.length < numOfRoom"
                type="addCard"
                class="item"
                @add="addPeople"
              ></personCard>
            </div>
          </div>
          <div class="line-select" style="padding-bottom:20px;">
            <div class="left">
              最晚到店
            </div>
            <div class="right">
              <en-select
                v-model="arriveTime"
                class="select-room-num"
                style="width:420px"
                :data="arriveTimeOptions"
              >
              </en-select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer-button-contain">
      <en-button class="confirm-button" @click="booking">
        预订
      </en-button>
    </div>
  </div>
</template>
<script>
import { request } from "en-js";
import dayjs from "dayjs";
import Store from "@/store";
import enItinerary from "../components/en-itinerary";
import enContacts from "../components/en-contacts";
import personCard from "./components/person-card";
import hotelData from "./hotelData";
import enTipsDialog from "../../../components/en-tips-dialog";
import overStandardDialog from "./components/over-standard-dialog";

export default {
  name: "Booking",
  components: {
    personCard,
    enItinerary,
    enContacts,
    enTipsDialog,
    overStandardDialog
  },
  data() {
    return {
      contactShow: false,
      tripShow: false,
      dialogTipsVisible: false,
      overStandardVisible: false,
      message: "",
      numOfRoom: 1,
      options: [
        { value: 1, label: "1间" },
        { value: 2, label: "2间" },
        { value: 3, label: "3间" }
      ],
      arriveTime: "",
      arriveTimeOptions: [],
      existList: [],
      personList: [],
      transferableTravelList: []
    };
  },
  mixins: [hotelData],
  computed: {
    dataSource() {
      const obj = this.$route.query;
      // console.log(obj);
      return obj;
    },
    hotelName() {
      return this.dataSource?.name;
    },
    categoryName() {
      return this.starDescription(this.dataSource);
    },
    address() {
      return this.dataSource?.address;
    },
    pics() {
      return [this.dataSource.thumbnailId];
    },
    arrivalDate() {
      // 入住时间,如果是当年则省略年的显示
      const isCurrentYear = dayjs(this.dataSource.arrivalDate).year() === new Date().getFullYear();
      const dateString = dayjs(this.dataSource.arrivalDate).format(isCurrentYear ? "MM月DD日" : "YYYY年MM月DD日");
      return dateString;
    },
    departureDate() {
      // 离店时间,如果是当年则省略年的显示
      const isCurrentYear = dayjs(this.dataSource.departureDate).year() === new Date().getFullYear();
      const dateString = dayjs(this.dataSource.departureDate).format(isCurrentYear ? "MM月DD日" : "YYYY年MM月DD日");
      return dateString;
    },
    totalDay() {
      // 总共住几天
      const start = dayjs(this.dataSource.arrivalDate);
      const end = dayjs(this.dataSource.departureDate);
      return end.diff(start, "day");
    },
    totalPrice() {
      const price = this.numOfRoom * Number(this.dataSource?.roomInfo?.ratePlan?.ratePlanOneRates) * this.totalDay;
      /* eslint-disable */
      const p = parseInt(price);
      /* eslint-enable */
      return p.toFixed(2);
    },
    breakfastType() {
      if (this.dataSource?.roomInfo?.ratePlan?.breakfastAmount) {
        let result;
        switch (this.dataSource?.roomInfo?.ratePlan?.breakfastAmount) {
          case 0:
            result = "不含早餐";
            break;
          case 1:
            result = "单早餐";
            break;
          default:
            result = "双早餐";
            break;
        }
        return result;
      }
      return "双早餐";
    }
  },
  watch: {
    numOfRoom(val) {
      if (val < this.personList.length) {
        const array = [];
        for (let i = 0; i < this.personList.length; i++) {
          if (array.length < val) {
            array.push(this.personList[i]);
          }
        }
        this.personList = array;
      }
    },
    personList(val) {
      this.existList = [...val];
    }
  },
  activated() {
    this.setArriveTimeOptions();
  },
  mounted() {
    const userInfo = Store.getters.userInfo;
    userInfo.id = userInfo.userId;
    userInfo.idNumber = userInfo.idcardNo;
    userInfo.idType = userInfo.idcardType;
    userInfo.mobile = userInfo.mobileNo;
    userInfo.flag = "001";
    this.personList = [userInfo];
  },
  methods: {
    addPeople() {
      // 增加人员的弹框显示
      this.contactShow = true;
    },
    getEnContactConfirm(val) {
      // 将新选择人员->加入人员列表
      this.personList.push(val);
    },
    deletePersonCard(val) {
      // 删除已选择人员
      this.personList.forEach((item, index, array) => {
        if (item.id === val.id) {
          array.splice(index, 1);
        }
      });
    },
    setArriveTimeOptions() {
      // 最晚到店时间数据
      const isCurrentDay = dayjs(new Date()).format("YYYY-MM-DD") === dayjs(this.dataSource.arrivalDate).format("YYYY-MM-DD");
      const options = [];
      if (isCurrentDay) {
        const currentHour = parseInt(dayjs(new Date()).format("HH"), 10);
        const showStartTime = currentHour + 1;
        for (let index = showStartTime; index <= 24; index++) {
          options.push({
            /* eslint-disable */
            label: (index - 24 > 0 ? "次日" + (index - 24) : index) + ":00",
            value: (index - 24 > 0 ? "次日" + (index - 24) : index) + ":00"
            /* eslint-enable */
          });
        }
      } else {
        for (let index = 14; index <= 30; index++) {
          if (index === 24) {
            options.push({
              /* eslint-disable */
              label: "23:59",
              value: "23:59"
              /* eslint-enable */
            });
          } else {
            options.push({
              /* eslint-disable */
              label: (index - 24 > 0 ? "次日" + (index - 24) : index) + ":00",
              value: (index - 24 > 0 ? "次日" + (index - 24) : index) + ":00"
              /* eslint-enable */
            });
          }
        }
      }
      this.arriveTimeOptions = options;
      this.arriveTime = options[0].value;
      // const d1 = this.dataSource.arrivalDate;
      // const d2 = dayjs(new Date()).format("YYYY-MM-DD");
      // if (d1 === d2) {
      //   this.arriveTime = "23:59";
      // } else {
      //   this.arriveTime = options[0].value;
      // }
    },
    // 点击预定
    booking() {
      if (this.personList.length < this.numOfRoom) {
        this.$message.error("请添加入住人");
        return;
      }
      const list = this.tripNodList();
      // console.log(list);
      const params = {
        tripNodList: JSON.stringify(list),
        tripNodeType: "001",
        type: "001"
      };
      this.checkSupplement(params);
    },
    // 检测超标
    @request(true)
    async checkSupplement(params) {
      // console.log(this.dataSource.tripId);

      const checkResult = await this.requestCheckSupplement(params);
      if (checkResult?.result?.isExcess === "000") {
        if (checkResult?.result?.standardMode === "001") {
          // 超标 可提交
          this.overStandardVisible = true;
        } else if (checkResult?.result?.standardMode === "000") {
          // 禁止提交
          // this.$message.error("行程超标, 禁止提交");
          this.message = "行程超标, 禁止提交";
          this.dialogTipsVisible = true;
        } else {
          // const list = this.tripNodList("000", "");
          // const params = {
          //   addTripNodeList: JSON.stringify(list)
          // };
          await this.chooseItinerary();
        }
      } else if (checkResult?.hotelAlert?.isAlert === "000") {
        this.message = checkResult?.hotelAlert?.content;
        this.dialogTipsVisible = true;
      } else {
        await this.chooseItinerary();
      }
      // console.log(checkResult);
      // this.tripShow = true;
    },
    confirmSubmitTripNodList() {
      // const list = this.tripNodList("000", "");
      // const params = {
      //   addTripNodeList: JSON.stringify(list)
      // };
      // bug37387
      // this.chooseItinerary();
    },
    // 超标事由
    async getOverStandardMemo(memo) {
      const list = this.tripNodList("001", memo);
      const params = {
        tripNodList: JSON.stringify(list),
        tripNodeType: "001",
        type: "001"
      };
      await this.checkSupplement(params);
    },
    async chooseItinerary() {
      const tripId = this.dataSource.tripId;
      if (tripId && tripId?.length > 0) {
        const list = this.tripNodList("000", "");
        const params = {
          id: tripId,
          addTripNodeList: JSON.stringify(list)
        };
        await this.submitTripNodList(params);
      } else {
        this.tripShow = true;
      }
    },
    async submitTripNodList(params) {
      // console.log(params);
      const rsp = await this.requestSaveTripNode(params);
      if (rsp?.id) {
        this.$router.push({
          path: "/business-travel/trip/tripDetail",
          query: {
            tripId: rsp.id,
            tripData: {}
          }
        });
      }
    },
    selectTripData(val) {
      const list = this.tripNodList("000", "");
      const params = {
        id: val.id,
        addTripNodeList: JSON.stringify(list)
      };
      this.submitTripNodList(params);
    },
    selectUnrelatedTrip() {
      const list = this.tripNodList("000", "");
      const params = {
        addTripNodeList: JSON.stringify(list)
      };
      this.submitTripNodList(params);
    },
    selectCreateNewTrip() {
      const list = this.tripNodList("000", "");
      const params = {
        addTripNodeList: JSON.stringify(list)
      };
      this.submitTripNodList(params);
    },
    tripNodList(flag, memo) {
      const list = [];
      for (let i = 0; i < this.personList.length; i++) {
        const person = this.personList[i];
        let cost = 0.0;
        let totalAmount = 0.0;
        for (let j = 0; j < this.dataSource.roomInfo.ratePlan?.nightlyRates.length; j++) {
          const night = this.dataSource.roomInfo.ratePlan.nightlyRates[j];
          cost += parseFloat(night.cost);
          totalAmount += parseFloat(night.salePrice);
        }
        const nodeObj = {
          hotelCode: this.dataSource.code,
          hotelName: this.dataSource.name,
          roomTypeId: this.dataSource.roomInfo.roomTypeId,
          roomTypeName: this.dataSource.roomInfo.name,
          ratePlanId: this.dataSource.roomInfo.ratePlan?.ratePlanId,
          ratePlanName: this.dataSource.roomInfo.ratePlan?.ratePlanName,
          address: this.dataSource.address,
          phone: this.dataSource.phone,
          totalDays: this.dataSource.days,
          arrivalDate: this.dataSource.arrivalDate,
          departureDate: this.dataSource.departureDate,
          baiduLat: this.dataSource.baiduLat,
          baiduLon: this.dataSource.baiduLon,
          city: this.dataSource.city,
          cityName: this.dataSource.cityName,
          enCityCode: this.dataSource.enCityName,
          stayId: person.id,
          stayName: person.name,
          stayMobile: person.phone,
          idType: person.idType,
          idNumber: person.idNumber,
          latestArrivalTime: `${dayjs(this.dataSource.arrivalDate).format("YYYY-MM-DD")} ${this.arriveTime}`,
          tripNodeType: "001",
          category: this.dataSource.category,
          cost,
          totalAmount,
          standardFlag: flag,
          status: "000"
        };
        if (memo && memo?.length > 0) {
          nodeObj.standardMemo = memo;
        }
        list.push(nodeObj);
      }
      return list;
    }
  }
};
</script>
<style scoped lang="scss">
$minWidth: 960px;
.hotel-booking-container {
  height: 100%;
  width: 100%;
  position: relative;
  background: #FFFFFF;
  .header-contain {
    height: 50px;
    border-bottom: 1px solid rgba(232, 236, 242, 0.98);
    font-size: 14px;
    font-family: MicrosoftYaHei;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    line-height: 50px;
    padding: 0 20px 0 16px;
    text-align: left;
    .click-title {
      cursor: pointer;
      -webkit-user-select: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-contain {
        display: flex;
        .back-icon {
          display: block;
          margin: 18px 2px 0 0;
        }
        .title {
          display: block;
          padding-top: 2px;
          height: 48px;
          line-height: 48px;
          text-align: left;
          font-size: 14px;
          font-weight: bold;
          color: #333;
        }
      }
    }
    .icon {
      font-size: 20px;
      color: #a9b5c6;
      width: 80px; //24px
      height: 50px;
      overflow: hidden;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  .booking-container {
    height: calc(100% - 50px - 64px);
    width: 100%;
    overflow: auto;
    .booking-board {
      background: white;
      //border-radius: 4px;
      min-width: $minWidth;
      height: 275px;
      overflow: hidden;

      .content {
        height: 220px;
        padding: 20px;
        border-bottom: 1px solid rgba(232, 236, 242, 0.98);
        width: 100%;
        display: flex;
        .hotel-detail-padding-container {
          border-radius: 4px;
          width: 100%;
          overflow: hidden;
          border: 1px solid rgba(232, 236, 242, 0.98);
          display: flex;
        }
        .hotel-detail {
          border-right: 1px solid rgba(232, 236, 242, 0.98);
          width: 50%;
          height: 180px;
          display: flex;
          .hotel-img {
            height: 180px;
            width: 180px;
            background: gray;
          }
          .detail-info {
            padding: 20px;
            width: calc(100% - 140px);
            text-align: left;
            .hotel-name {
              font-size: 16px;
              font-family: MicrosoftYaHei;
              font-weight: 400;
              color: rgba(51, 51, 51, 1);
            }
            .hotel-label {
              display: inline-block;
              font-size: 12px;
              font-family: MicrosoftYaHei;
              font-weight: 400;
              color: rgba(247, 191, 39, 1);
              background: rgba(247, 191, 39, 0.2);
              border-radius: 4px;
              overflow: hidden;
              height: 24px;
              line-height: 22px;
              margin-top: 18px;
              padding: 0 8px;
            }
            .hotel-location {
              font-size: 12px;
              font-family: MicrosoftYaHei;
              font-weight: 400;
              color: rgba(145, 161, 183, 1);
              margin-top: 18px;
            }
          }
        }
        .time-detail {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 50%;
          .start-time {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: rgba(99, 108, 120, 1);
            .desc {
              font-size: 12px;
              font-family: MicrosoftYaHei;
              font-weight: 400;
              color: rgba(99, 108, 120, 1);
              margin-bottom: 10px;
              text-align: center;
            }
          }
          .total-time {
            font-size: 12px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: rgba(145, 161, 183, 1);
            margin: 0 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            .title {
              text-align: center;
            }
            .icon {
              height: 12px;
              width: 56px;
            }
          }
          .end-time {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: rgba(99, 108, 120, 1);
            .desc {
              font-size: 12px;
              font-family: MicrosoftYaHei;
              font-weight: 400;
              color: rgba(99, 108, 120, 1);
              margin-bottom: 10px;
              text-align: center;
            }
          }
        }
      }
      .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 55px;
        background: rgba(251, 251, 252, 1);
        border-bottom: 1px solid rgba(232, 236, 242, 0.98);
        padding: 0 20px;
        .left-area {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(145, 161, 183, 1);
          line-height: 55px;
        }
        .right-area {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(145, 161, 183, 1);
          line-height: 30px;
          height: 30px;
        }
      }
    }
    .booking-info {
      min-width: $minWidth;
      height: calc(100% - 330px);
      background: white;
      border-radius: 4px;
      .header {
        height: 50px;
        padding: 0 20px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        text-align: left;
        line-height: 50px;
        border-bottom: 1px solid rgba(232, 236, 242, 0.98);
      }
      .content {
        height: calc(100% - 114px - 50px);

        .line-select {
          padding: 20px 20px 0 20px;
          display: flex;
          align-items: center;
          .left {
            min-width: 48px;
            text-align: left;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
          }
          .right {
            margin-left: 12px;
            display: flex;
            .item {
              margin-right: 12px;
            }
          }
        }
        //border-bottom: 1px solid rgba(232, 236, 242, 0.98);
      }
    }
    .seaprator-contain {
      background: rgba(29, 37, 50, 1);
      width: 100%;
      min-width: $minWidth;
      .seaprator-top {
        // background: rgba(255, 255, 255, 1);
        // border-radius: 0 0 4px 4px;
        height: 4px;
        width: 100%;
      }
      .seaprator-bottom {
        margin-top: 4px;
        background: rgba(255, 255, 255, 1);
        border-radius: 4px 4px 0 0;
        height: 4px;
        width: 100%;
      }
    }
  }
  .footer-button-contain {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(232, 236, 242, 0.98);
    padding: 0 20px;
    height: 64px;
    text-align: right;
    line-height: 64px;
    .confirm-button {
      height: 32px;
      width: 72px;
    }
  }
}
</style>

<style lang="scss">
.el-popper[x-placement^="bottom"] {
  .popper__arrow {
    &::after {
      top: 0 !important;
    }
  }
}
.el-popper[x-placement^="top"] {
  .popper__arrow {
    &::after {
      bottom: 0 !important;
    }
  }
}
.custom-hotel-book-popover {
  .popper__arrow {
    &::after {
      // border-top-color: orange !important;
      // border-bottom-color: orange !important;
      border-top-color: #666975 !important;
      border-bottom-color: #666975 !important;
    }
  }
  .amount-hotel-book-popover {
    margin: -13px;
    padding: 8px 12px 12px 12px;
    // background-color: orange;
    background-color: #666975;
    border: 0 solid #fff;
    border-radius: 4px;
    height: 34px;
    min-width: 100px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #fff;
  }
}
</style>
