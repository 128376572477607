export default {
  hotelDataSource: {
    arrivalDate: "2020-01-01",
    departureDate: "2020-01-02",
    records: [
      {
        address: "芙蓉中路一段605号",
        baiduLat: 28.197529,
        baiduLon: 112.985852,
        businessZone: "五一广场/贺龙商圈",
        category: "2",
        city: "001015002",
        code: "0010150021609191750394039270vF01657",
        commentsCore: 5,
        days: 0,
        lowerPrice: 0,
        description: "酒店详细描述",
        district: "芙蓉区",
        enCityId: "C430100",
        enName: "7 Days Inn Furong Square",
        fullHouse: "000",
        id: "266731925486084096",
        name: "7天优品酒店(长沙芙蓉广场店)",
        phone: "0731-82770098",
        thumbnailId: "https://image.517la.com/group1/M00/09/83/rBUzI1vtJ96AOSu-AADkHXjgO0E485.jpg"
      }
    ]
  }
};
