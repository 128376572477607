<!--
 * @Author: Fenngding
 * @Date: 2019-10-10 10:00:34
 * @LastEditors: zhulin
 * @LastEditTime: 2021-07-13 10:58:42
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div class="hotel-container">
    {{ a }}
    <en-carousel>
      <el-form label-position="left" :model="query" class="entry">
        <div class="search-title">
          酒店入住
        </div>
        <el-form-item>
          <div class="label">
            入住城市
          </div>
          <en-city
            :value="query.cityInfo"
            type="hotel"
            placeholder="请选择城市"
            @input="cityInfoChanged"
          ></en-city>
        </el-form-item>
        <el-form-item>
          <div class="label">
            入住日期
          </div>
          <el-date-picker
            v-model="query.arrivalDate"
            prefix-icon="1"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerInOptions"
            @change="arrivalDateChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <div class="label">
            离店日期
          </div>
          <el-date-picker
            v-model="query.departureDate"
            prefix-icon="1"
            type="date"
            placeholder="选择日期"
            :picker-options="pickerOutOptions"
            @change="arrivalDateChange"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <div class="label">
            关键词
          </div>
          <el-select
            v-model="query.keywords"
            value-key="name"
            :clearable="true"
            filterable
            remote
            reserve-keyword
            placeholder="酒店名/地标/商圈"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="onChange"
          >
            <el-option
              v-for="item in seachOptions"
              :key="item.value"
              :label="item.label"
              :value="item"
            >
              <en-icon
                name="ditu-jiudian"
                size="16px"
                style="fill:#ff00ff;float:left;margin-top:7px;margin-right:5px;"
              ></en-icon>
              <span style="float:left">{{ item.label }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-button
          type="primary"
          class="search-button"
          :loading="buttonLoading"
          @click.native="search"
        >
          立即搜索
        </el-button>
      </el-form>
    </en-carousel>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
// mapMutations
import { mapActions, mapGetters } from "vuex";
import enCity from "../components/en-city";
import enCarousel from "../components/en-carousel";
import hotelData from "./hotelData";

export default {
  name: "HotelEntry",
  components: {
    enCity,
    enCarousel
  },
  props: {},
  data() {
    return {
      seachOptions: [],
      resourceList: [],
      loading: false,
      photoList: [
        "https://images.softfuxi.com/static/hotel_banner114409.jpg",
        "https://images.softfuxi.com/static/hotel_banner114409.jpg",
        "https://images.softfuxi.com/static/hotel_banner114409.jpg"
      ],
      query: {
        cityName: "", // 城市名
        city: "", // 城市编码
        cityInfo: {},
        arrivalDate: dayjs(new Date()), // 入住日期(默认当天)
        departureDate: dayjs(new Date().getTime() + 8.64e7), // 离店日期(默认当天后1天)
        keywords: {} // 搜索关键字
      },
      buttonLoading: false,
      a: this.photoList,
      pickerInOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        }
      },
      pickerOutOptions: {
        disabledDate: (time) => {
          if (this.query.arrivalDate) {
            return (
              time.getTime() < new Date(
                dayjs(this.query.arrivalDate).format("YYYY-MM-DD")
              ).getTime()
            );
          }
          return time.getTime() < new Date().getTime();
          // 如果函数里处理的数据比较麻烦,也可以单独放在一个函数里,避免data数据太臃肿
          // return this.dealDisabledDate(time);
        }
      }
    };
  },
  activated() {
    this.loadCacheData();
  },
  computed: {
    ...mapGetters("businessTravel", {
      cacheHotelInfo: "hotelInfo"
    })
    // cacheHotelInfo() {
    //   return this.$store.getters["businessTravel/hotelInfo"];
    // }
  },
  mixins: [hotelData],
  mounted() {},
  methods: {
    ...mapActions("businessTravel", [
      "setHotelInfo"
    ]),
    // 加载缓存数据
    loadCacheData() {
      const query = this.$route.query;
      // console.log(query);
      const obj = cloneDeep(this.cacheHotelInfo);
      if (!query) {
        if (obj && obj.city) {
          this.query.city = obj.city;
        }
        if (obj && obj.cityName) {
          this.query.cityName = obj.cityName;
        }
        if (obj.city && obj.cityName) {
          this.query.cityInfo = {
            cityCode: obj.city,
            cityName: obj.cityName,
            enCityName: obj.cityName
          };
        }
      }

      let needLoadOutDate = true;
      if (obj && obj.arrivalDate) {
        this.query.arrivalDate = obj.arrivalDate;
        if (dayjs(obj.arrivalDate).isBefore(new Date())) {
          this.query.arrivalDate = dayjs(new Date()).format("YYYY-MM-DD");
          if (dayjs(obj.departureDate).isBefore(new Date() + 8.64e7)) {
            this.arrivalDateChange();
            needLoadOutDate = false;
          }
        }
      }
      if (needLoadOutDate && obj && obj.departureDate) {
        this.query.departureDate = obj.departureDate;
      }
      if (obj && obj.cityInfo) {
        this.query.cityInfo = obj.cityInfo;
        this.query.cityName = obj.cityInfo.cityName;
        this.query.city = obj.cityInfo.cityCode;
      }
      this.bookData.cityName = this.query.cityName;
      this.bookData.city = this.query.city;
      // debugger;
    },
    // 加载模糊搜索数据
    async remoteMethod(query) {
      if (this.query.cityName.length === 0) {
        this.$notify({
          type: "warning",
          title: "请您根据提示完成",
          message: "入住城市不能为空"
        });
        return;
      }
      if (query !== "") {
        this.loading = true;
        const list = await this.requestLocationTips(query);
        if (list?.length > 0) {
          this.seachOptions = [];
          for (let i = 0; i < list.length; i++) {
            if (list[i]?.lat && list[i]?.lon) {
              this.seachOptions.push({
                value: list[i]?.name,
                label: list[i]?.name,
                address: list[i]?.address,
                district: list[i]?.district,
                lat: list[i]?.lat,
                lon: list[i]?.lon,
                name: list[i]?.name
              });
            }
          }
        } else {
          this.seachOptions = [];
        }
        this.loading = false;
        // setTimeout(() => {
        //   this.loading = false;
        //   this.seachOptions = this.resourceList.filter((item) => item.label.toLowerCase().indexOf(query.toLowerCase()) > -1);
        // }, 500);
      } else {
        this.seachOptions = [];
      }
    },
    // 城市数据变化
    cityInfoChanged(info) {
      this.query.cityInfo = info;
      this.query.cityName = info.enCityName;
      this.query.city = info.cityCode;

      this.bookData.cityName = this.query.cityName;
      this.bookData.city = this.query.city;
      this.comimitHotelInfo();
    },
    // 日期改变
    arrivalDateChange() {
      if (this.query.arrivalDate && this.query.departureDate) {
        const isOk = dayjs(this.query.arrivalDate).isBefore(
          dayjs(this.query.departureDate).format("YYYY-MM-DD")
        );
        if (!isOk) {
          this.query.departureDate = dayjs(
            new Date(this.query.arrivalDate).getTime() + 8.64e7
          );
        }
      } else if (this.query.arrivalDate) {
        if (
          this.query.departureDate === undefined
          || this.query.departureDate === null
        ) {
          this.query.departureDate = dayjs(
            new Date(this.query.arrivalDate).getTime() + 8.64e7
          );
        }
      }
      this.comimitHotelInfo();
    },
    // 校验数据
    checkDataValid() {
      if (this.query.cityName.length === 0) {
        this.$notify({
          type: "warning",
          title: "请您根据提示完成",
          message: "入住城市不能为空"
        });
        return false;
      }

      if (
        this.query.arrivalDate === undefined
        || this.query.arrivalDate === null
      ) {
        this.$notify({
          type: "warning",
          title: "请您根据提示完成",
          message: "入店日期不能为空"
        });
        return false;
      }

      if (
        this.query.departureDate === undefined
        || this.query.departureDate === null
      ) {
        this.$notify({
          type: "warning",
          title: "请您根据提示完成",
          message: "离店日期不能为空"
        });
        return false;
      }

      // 检查入住日期是否早于离店日期
      if (this.query.arrivalDate && this.query.departureDate) {
        // 判断是否输入了有效日期
        const isOk = dayjs(this.query.arrivalDate).isBefore(
          this.query.departureDate
        );
        if (!isOk) {
          this.$notify({
            type: "warning",
            title: "请您根据提示完成",
            message: "离店日期不能早于入店日期"
          });
        }
        return isOk;
      }
      return true;
    },
    // 模糊搜索选中数据
    onChange(val) {
      if (val) {
        this.query.keywords = val;
        this.query.landmarkSearch = "000";
      } else {
        this.query.keywords = {};
        this.query.landmarkSearch = "001";
      }
    },
    // 立即搜索
    search() {
      this.buttonLoading = true;
      if (!this.checkDataValid()) {
        this.buttonLoading = false;
        return;
      }
      this.$router.push({
        path: "/business-travel/hotel/query",
        query: {
          tripId: this.$route.query?.tripId,
          city: this.query.city,
          cityName: this.query.cityName,
          arrivalDate: dayjs(this.query.arrivalDate).format("YYYY-MM-DD"),
          departureDate: dayjs(this.query.departureDate).format("YYYY-MM-DD")
        }
      });
      this.buttonLoading = false;
      this.comimitHotelInfo();
    },
    comimitHotelInfo() {
      this.query.seachOptions = [...this.seachOptions];
      const obj = cloneDeep(this.query);
      this.setHotelInfo(obj);
    }
  }
};
</script>
<style scoped lang="scss">
.hotel-container {
  height: 100%;
  width: 100%;
  background: #FFFFFF;
  overflow: hidden;
  position: relative;
  .title-enfry {
    position: absolute;
    top: calc(50% - 30px);
    left: calc(30% - 145px);
    z-index: 3;
    font-size: 36px;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    line-height: 40px;
  }
  .bg-pic {
    height: 100%;
    width: 100%;
    overflow: hidden;
    & /deep/ .el-carousel__container {
      height: 100%;
    }
    .bg {
      height: 100%;
      width: 100%;
    }
    .pic {
      height: 100%;
      width: 100%;
      background-size: cover !important;
      background-repeat: no-repeat;
      background-position: center !important;
    }
  }
  .entry {
    height: 364px;
    width: 520px;
    position: absolute;
    top: 50px;
    right: 50px;
    background: rgba(255, 255, 255, 1);
    border-radius: 2px;
    z-index: 800;
    padding: 20px 40px;
    border: 8px solid rgba(70, 148, 223, 1);
    .label {
      width: 60px;
      color: rgba(145, 161, 183, 1);
      position: absolute;
      z-index: 1;
      left: 11px;
      text-align: left;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: rgba(145, 161, 183, 1);
    }

    .search-title {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      text-align: left;
      margin-bottom: 20px;
    }
    & /deep/ .el-select {
      width: 100%;
      .el-select__input {
        height: 32px;
        font-size: 12px;
        margin-left: 0;
        padding-left: 70px;
        line-height: 32px;
      }
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    & /deep/ .el-form-item {
      position: relative;
      width: 100%;
      .el-form-item__content {
        width: 100%;
      }
      & /deep/ .el-form-item__label {
        position: absolute;
        z-index: 1;
        left: 11px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(145, 161, 183, 1);
      }
      .el-input__inner {
        height: 32px;
        font-size: 12px;
        padding-left: 70px;
        line-height: 32px;
      }
      & /deep/ .el-input .el-input__inner {
        padding-left: 70px;
        line-height: 32px;
      }
      & /deep/ .el-date-editor.el-input {
        width: 100%;
        & /deep/ .el-input__prefix {
          visibility: hidden;
        }
      }
    }
    .search-button {
      width: 424px;
      font-size: 16px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>
<style lang="scss">
/deep/ .el-date-picker__header-label {
  font-size: 14px;
}
</style>
