<!--
 * @Author: Fenngding
 * @Date: 2019-10-10 10:00:34
 * @LastEditors: zhulin
 * @LastEditTime: 2021-06-16 16:39:12
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div class="query-condition-container">
    <div class="query-stick-area">
      <div class="choose-column">
        <div class="title-area">
          位置
        </div>
        <div class="condition-choose">
          <div class="is-limit" :class="{ not: !locationLimit }" @click="clearLocation">
            不限
          </div>
          <chooseItem
            v-for="item in locationList"
            :key="item.label"
            v-model="queryOption.location"
            class="condition-item"
            :name="item.title"
            :options="item.options"
            @input="positionDataSelect"
          ></chooseItem>
        </div>
      </div>
      <div class="choose-column">
        <div class="title-area">
          价格
        </div>
        <div class="condition-choose">
          <div class="is-limit" :class="{ not: !priceLimit }" @click="clearPrice('all')">
            不限
          </div>
          <div
            v-for="(item, index) in priceList"
            :key="index"
            class="condition-item"
            :class="{
              active: queryOption.price.find((price) => price.value === item.value)
            }"
            style="display:inline-block;"
            @click="selectPrice(item)"
          >
            {{ item.label }}
          </div>
          <div class="custom-price">
            <span class="custom-price-title">
              自定义
            </span>
            <el-input
              v-model="customPrice.minPrice"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
              placeholder="请输入"
              maxlength="10"
              @blur="blurChange('min')"
              @focus="focusChange('min')"
            ></el-input>
            <span> - </span>
            <el-input
              v-model="customPrice.maxPrice"
              onkeyup="this.value=this.value.replace(/[^\d.]/g,'');"
              placeholder="请输入"
              maxlength="10"
              @blur="blurChange('max')"
              @focus="focusChange('max')"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <en-collapse-area>
      <div v-show="isExpose" class="query-toggle-area" :class="{ 'is-expose': isExpose }">
        <div class="choose-column">
          <div class="title-area">
            星级
          </div>
          <div class="condition-choose">
            <div class="is-limit" :class="{ not: !starLimit }" @click="clearStar">
              不限
            </div>
            <div
              v-for="(item, index) in hoteLevelList"
              :key="index"
              class="condition-item"
              style="min-width:50px;"
              :class="{
                active: queryOption.star.find((star) => star.value === item.value)
              }"
              @click="selectStar(item)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="choose-column">
          <div class="title-area">
            品牌
          </div>
          <div class="condition-choose hotel-choose">
            <div class="is-limit" :class="{ not: !brandLimit }" @click="clearBrand">
              不限
            </div>
            <div
              v-for="(item, index) in brandList"
              :key="index"
              class="condition-item hotel-item"
              :class="{
                active: queryOption.brand.find((brand) => brand.value === item.value)
              }"
              @click="selectBrand(item)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div class="condition-show-area">
          <div class="title-area">
            选择条件
          </div>
          <div class="condition-show-list">
            <div v-for="(item, index) in allSelect" :key="index" class="option-container">
              <div class="option-name">
                {{ item.label }}
              </div>
              <en-icon class="option-del-icon" size="12" style="width:12px;height:12px" name="guanbi-danchuang" @click.native="deleteSingleOption(item)"></en-icon>
            </div>
            <div v-show="allSelect.length" class="clear-button" @click="clearAllOption">
              清空
            </div>
          </div>
        </div>
      </div>
    </en-collapse-area>

    <div class="expose-button" :class="{ 'is-expose': isExpose }" @click="isExpose = !isExpose">
      {{ isExpose ? "收起" : "展开" }}
      <i class="el-icon-arrow-down" :class="{ expose: isExpose }"></i>
    </div>
  </div>
</template>
<script>
import chooseItem from "./chooseItem";

export default {
  name: "Enchoosecard",
  components: { chooseItem },
  props: {
    positionData: {
      type: Object,
      default: () => ({
        businessZone: [
          // {
          //   businessZone: "商圈广场"
          // }
        ],
        district: [
          // {
          //   district: "行政区域"
          // }
        ]
      })
    },
    hotelBrand: {
      type: Object,
      default: () => ({
        brand: [
          // { brandId: "品牌7天" }
        ]
      })
    }
  },
  data() {
    return {
      minFocus: false,
      maxFocus: false,
      isExpose: false,
      priceList: [
        {
          key: "price",
          label: "￥200以下",
          value: 6,
          valueText: "200"
        },
        {
          key: "price",
          label: "￥200-￥300",
          value: 7,
          valueText: "200-300"
        },
        {
          key: "price",
          label: "￥300-￥500",
          value: 8,
          valueText: "300-500"
        },
        {
          key: "price",
          label: "￥500以上",
          value: 9,
          valueText: "500"
        }
      ],
      customPrice: {
        minPrice: "",
        maxPrice: ""
      },
      locationList: [
        {
          title: "商圈",
          options: [
            // { label: "我是商圈1", value: 1 },
            // { label: "我是商圈2", value: 2 }
          ]
        },
        {
          title: "区域",
          options: [
            // { label: "我是车站1", value: 3 },
            // { label: "我是机场1", value: 4 }
          ]
        }
      ],
      hoteLevelList: [
        { key: "category", label: "经济型", value: 1 },
        { key: "category", label: "二星/其他", value: 2 },
        { key: "category", label: "三星/舒适", value: 3 },
        { key: "category", label: "四星/高档", value: 4 },
        { key: "category", label: "五星/豪华", value: 5 }
      ],
      brandList: [
        // { label: "7天连锁酒店", value: 17 }
      ],
      queryOption: {
        location: [],
        price: [],
        star: [],
        brand: []
      }
    };
  },
  computed: {
    locationLimit() {
      return !this.queryOption.location.length;
    },
    priceLimit() {
      return !this.queryOption.price.length && this.customPrice.minPrice === "" && this.customPrice.maxPrice === "";
    },
    starLimit() {
      return !this.queryOption.star.length;
    },
    brandLimit() {
      return !this.queryOption.brand.length;
    },
    allSelect() {
      const list = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.queryOption) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.queryOption.hasOwnProperty(key)) {
          list.push(...this.queryOption[key]);
        }
      }
      return list;
    }
  },
  watch: {
    positionData() {
      const businessZone = {
        title: "商圈",
        options: []
      };
      const district = {
        title: "区域",
        options: []
      };

      for (let i = 0; i < this.positionData?.businessZone?.length; i++) {
        const obj = this.positionData.businessZone[i];
        businessZone.options.push({
          key: "position",
          label: obj.businessZone,
          value: i + 100
        });
      }
      for (let i = 0; i < this.positionData?.district?.length; i++) {
        const obj = this.positionData.district[i];
        district.options.push({
          key: "position",
          label: obj.district,
          value: i + 500
        });
      }
      this.locationList = [businessZone, district];
    },
    hotelBrand() {
      const brandList = [];
      for (let i = 0; i < this.hotelBrand?.brand?.length; i++) {
        const obj = this.hotelBrand.brand[i];
        brandList.push({
          key: "brand",
          label: obj.brandId,
          value: i + 1000
        });
      }
      this.brandList = [...brandList];
    },
    "customPrice.maxPrice": {
      handler(value) {
        if (value.length > 0) {
          this.clearPrice();
        }
      }
    },
    "customPrice.minPrice": {
      handler(value) {
        if (value.length > 0) {
          this.clearPrice();
        }
      }
    }
  },
  methods: {
    // 查询条件发生变动,统一传出请求参数
    handleSelectDataCommit(list) {
      if (!this.minFocus && !this.maxFocus) {
        let price = "";
        if (this.customPrice.minPrice?.length > 0 && this.customPrice.maxPrice?.length > 0) {
          if (parseFloat(this.customPrice.minPrice) > parseFloat(this.customPrice.maxPrice)) {
            const max = this.customPrice.minPrice;
            const minPrice = this.customPrice.maxPrice;
            const maxPrice = max;
            price = `${minPrice}-${maxPrice}`;
          } else {
            price = `${this.customPrice.minPrice}-${this.customPrice.maxPrice}`;
          }
        }
        const params = {
          position: "",
          price,
          category: "",
          brand: ""
        };

        for (let i = 0; i < list?.length; i++) {
          const obj = list[i];
          if (obj.key === "position") {
            if (params.position?.length > 0) {
              params.position = `${params.position},${obj.label}`;
            } else {
              params.position = obj.label;
            }
          } else if (obj.key === "price") {
            params.price = obj.valueText;
          } else if (obj.key === "category") {
            if (params.category?.length > 0) {
              params.category = `${params.category},${obj.value}`;
            } else {
              params.category = `${obj.value}`;
            }
          } else if (obj.key === "brand") {
            if (params.brand?.length > 0) {
              params.brand = `${params.brand},${obj.label}`;
            } else {
              params.brand = obj.label;
            }
          }
        }
        this.$emit("filterParams", params);
      }
    },
    clearLocation() {
      this.queryOption.location = [];
      this.handleSelectDataCommit(this.allSelect);
    },
    clearPrice(type) {
      if (type === "includeInput") {
        this.queryOption.price = [];
        this.customPrice.maxPrice = "";
        this.customPrice.minPrice = "";
      } else if (type === "all") {
        this.queryOption.price = [];
        this.customPrice.maxPrice = "";
        this.customPrice.minPrice = "";
        this.handleSelectDataCommit(this.allSelect);
      } else {
        this.queryOption.price = [];
      }
    },
    clearBrand() {
      this.queryOption.brand = [];
      this.handleSelectDataCommit(this.allSelect);
    },
    clearStar() {
      this.queryOption.star = [];
      this.handleSelectDataCommit(this.allSelect);
    },
    deleteSingleOption(option) {
      const index = this.queryOption.location.findIndex((item) => option.value === item.value);
      if (index >= 0) {
        this.queryOption.location.splice(index, 1);
      }
      const index1 = this.queryOption.price.findIndex((item) => option.value === item.value);
      if (index1 >= 0) {
        this.queryOption.price.splice(index, 1);
      }
      const index2 = this.queryOption.star.findIndex((item) => option.value === item.value);
      if (index2 >= 0) {
        this.queryOption.star.splice(index, 1);
      }
      const index3 = this.queryOption.brand.findIndex((item) => option.value === item.value);
      if (index3 >= 0) {
        this.queryOption.brand.splice(index, 1);
      }
      this.handleSelectDataCommit(this.allSelect);
    },
    clearAllOption() {
      console.log("clearAllOption");
      this.queryOption.location = [];
      this.queryOption.price = [];
      this.queryOption.star = [];
      this.queryOption.brand = [];
      this.customPrice.maxPrice = "";
      this.customPrice.minPrice = "";
      this.handleSelectDataCommit(this.allSelect);
    },
    selectPrice(option) {
      const index = this.queryOption.price.findIndex((item) => option.value === item.value);
      if (index >= 0) {
        this.queryOption.price.splice(index, 1);
      } else {
        this.clearPrice("includeInput");
        this.queryOption.price = [];
        this.queryOption.price.push(option);
      }

      this.handleSelectDataCommit(this.allSelect);
    },
    selectStar(option) {
      const index = this.queryOption.star.findIndex((item) => option.value === item.value);
      if (index >= 0) {
        this.queryOption.star.splice(index, 1);
      } else {
        this.queryOption.star.push(option);
      }

      this.handleSelectDataCommit(this.allSelect);
    },
    selectBrand(option) {
      const index = this.queryOption.brand.findIndex((item) => option.value === item.value);
      if (index >= 0) {
        this.queryOption.brand.splice(index, 1);
      } else {
        this.queryOption.brand.push(option);
      }

      this.handleSelectDataCommit(this.allSelect);
    },
    positionDataSelect() {
      this.handleSelectDataCommit(this.allSelect);
    },
    blurChange(type) {
      console.log("1111111", this.queryOption.price);
      if (type === "min") {
        this.minFocus = false;
      } else {
        this.maxFocus = false;
      }
      setTimeout(() => {
        if (!this.minFocus && !this.maxFocus) {
          this.refreshCustomPrice();
        }
      }, 200);
    },
    focusChange(type) {
      // 获取焦点
      if (type === "min") {
        this.minFocus = true;
        this.maxFocus = false;
      } else {
        this.minFocus = false;
        this.maxFocus = true;
      }
    },
    refreshCustomPrice() {
      if (this.queryOption.price.length > 0 || (!this.customPrice.minPrice && !this.customPrice.maxPrice)) {
        return;
      }
      if (this.customPrice.minPrice.length === 0 || !this.customPrice.minPrice) {
        this.customPrice.minPrice = 0;
      }
      if (this.customPrice.maxPrice.length === 0 || !this.customPrice.maxPrice) {
        this.customPrice.maxPrice = 0;
      }
      if (parseFloat(this.customPrice.minPrice) > parseFloat(this.customPrice.maxPrice)) {
        const max = this.customPrice.minPrice;
        this.customPrice.minPrice = this.customPrice.maxPrice;
        this.customPrice.maxPrice = max;
      }
      this.queryOption.price.splice(0, 1);
      this.queryOption.price.push({
        label: `￥${this.customPrice.minPrice}-￥${this.customPrice.maxPrice}`,
        value: 1001,
        key: "price",
        valueText: `${this.customPrice.minPrice}-${this.customPrice.maxPrice}`
      });
      this.handleSelectDataCommit(this.allSelect);
    }
  }
};
</script>
<style scoped lang="scss">
.query-condition-container {
  border: 1px solid rgba(232, 236, 242, 1);
  border-radius: 4px;
  padding: 0 20px;
  position: relative;
  min-width: 900px;
  .title-area {
    width: 64px;
    line-height: 48px;
    height: 48px;
    font-size: 12px;
    text-align: left;
    font-family: MicrosoftYaHei;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
  }
  .choose-column {
    height: auto !important;
    line-height: 48px;
    text-align: left;
    font-family: MicrosoftYaHei;
    font-size: 12px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    .hotel-choose {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start !important;
      margin-top: 12px;
    }
    .condition-choose {
      width: calc(100% - 64px);
      height: auto;
      line-height: 48px;
      text-align: left;
      display: inline-flex;
      vertical-align: middle;
      justify-content: flex-start;
      align-items: center;
      .is-limit {
        min-width: 40px;
        height: 24px;
        background: rgba(70, 148, 223, 1);
        border-radius: 4px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 24px;
        text-align: center;
        margin-right: 30px;
        cursor: pointer;
        &.not {
          color: #636c78;
          background: white;
          cursor: pointer;
        }
      }
      .hotel-item {
        min-width: 50px !important;
        margin-right: 20px !important;
        margin-bottom: 12px;
      }
      .condition-item {
        padding: 0 8px;
        height: 24px;
        min-width: 72px;
        text-align: left !important;
        background: white;
        color: #636c78;
        border-radius: 4px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
        margin-right: 35px;
        cursor: pointer;
        &.active {
          color: rgba(255, 255, 255, 1);
          background: rgba(70, 148, 223, 1);
        }
      }
      .custom-price {
        min-width: 222px;
        .custom-price-title {
          display: inline-block;
          margin-right: 10px;
        }
        .el-input {
          width: 80px;
        }
        &/deep/.el-input__inner {
          padding: 0 12px;
        }
      }
    }
  }
  .expose-button {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 52px;
    height: 24px;
    background: rgba(25, 48, 70, 1);
    color: white;
    opacity: 0.5;
    border-radius: 4px 4px 0 0;
    font-size: 12px;
    line-height: 24px;
    cursor: pointer;
    text-align: center;
    .el-icon-arrow-down {
      transform: scaleX(0.9);
      transition: transform 0.3s;
      &.expose {
        transform: rotateZ(180deg);
      }
    }
  }
  .query-stick-area {
    .choose-column {
      height: 48px;
      &:first-child {
        border-top: 0 solid rgba(232, 236, 242, 1);
      }
      border-top: 1px solid rgba(232, 236, 242, 1);
    }
  }
  .query-toggle-area {
    transition: all 0.5s ease;
    overflow-y: hidden;
    max-height: 0;
    &.is-expose {
      max-height: 1048px;
      .choose-column {
        border-top: 1px solid rgba(232, 236, 242, 1) !important;
      }
      .condition-show-area {
        border-top: 1px solid rgba(232, 236, 242, 1) !important;
      }
    }
    .choose-column {
      transition: all 0.5s ease;
      height: 48px;
      &:first-child {
        border-top: 1px solid transparent;
      }
      border-top: 1px solid rgba(232, 236, 242, 1);
    }
    .condition-show-area {
      transition: all 0.5s ease;
      min-height: 64px;
      border-top: 1px solid rgba(232, 236, 242, 1);
      display: flex;
      .title-area {
        width: 64px;
        line-height: 64px;
        height: 64px;
        font-size: 12px;
        text-align: left;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: #333333;
        align-self: flex-start;
      }
      .condition-show-list {
        width: calc(100% - 64px);
        display: inline-flex;
        vertical-align: middle;
        min-height: 64px;
        justify-content: flex-start;
        align-items: flex-end;
        flex-wrap: wrap;
        padding-bottom: 16px;
        .option-container {
          -webkit-user-select: none;
          margin-top: 16px;
          height: 32px;
          background: rgba(245, 248, 252, 1);
          border: 1px solid rgba(220, 229, 236, 1);
          border-radius: 4px;
          line-height: 32px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          padding: 0 12px;
          margin-right: 12px;
          display: inline-flex;
          justify-content: flex-start;
          align-items: center;
          .option-name {
            vertical-align: middle;
            line-height: 28px;
            display: inline-block;
            height: 30px;
            margin-right: 12px;
          }
          .option-del-icon {
            color: #a9b5c6;
            vertical-align: middle;
            line-height: 30px;
            cursor: pointer;
          }
        }
        .clear-button {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(70, 148, 223, 1);
          line-height: 30px;
          margin-top: 16px;
          cursor: pointer;
          -webkit-user-select: none;
        }
      }
    }
  }
}
</style>
