<template>
  <div class="dialog-alert-contain">
    <en-dialog
      class="my-dialog"
      :visible="dialogVisible"
      title="酒店详情"
      en-fullscreen
      en-body-scroll
      @close="close"
    >
      <div class="my-body-container">
        <div class="body-top-contain">
          <div class="top">
            <div class="title">{{ hotelName }}</div>
          </div>
          <div class="content">{{ hotelDetail.introEditor }}</div>
        </div>

        <div class="body-center-contain">
          <div class="top">
            <div class="title">酒店设施</div>
          </div>
          <div class="content-contain">
            <div class="item-title">网络</div>
            <div v-if="roomFacilityList.length > 0" class="content center-content" >
              <div v-for="item in roomFacilityList" :key="item">
                <div v-if="item.length > 0" class="item-contain">
                  <en-icon size="16" class="icon" name="yixuan"></en-icon>
                  <div class="text">{{ item }}</div>
                </div>
              </div>
            </div>
            <div class="item-title">交通服务</div>
            <div v-if="generalFacilityList.length > 0" class="content center-content" >
              <div v-for="item in generalFacilityList" :key="item">
                <div v-if="item.length > 0" class="item-contain">
                  <en-icon size="16" class="icon" name="yixuan"></en-icon>
                  <div class="text">{{ item }}</div>
                </div>
              </div>
            </div>
            <div class="item-title">前台服务</div>
            <div v-if="facilityList.length > 0" class="content center-content" >
              <div v-for="item in facilityList" :key="item">
                <div v-if="item.length > 0" class="item-contain">
                  <en-icon size="16" class="icon" name="yixuan"></en-icon>
                  <div class="text">{{ item }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="body-bottom-contain">
          <div class="top">
            <div class="title">酒店政策</div>
          </div>
          <div class="content">{{ hotelDetail.hotelPolicy }}</div>
        </div>
      </div>
    </en-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    hotelDetail: {
      type: Object,
      default: () => ({
        airportPickUpService: "",
        brandId: "",
        conferenceAmenities: "",
        createTime: "2018-11-26 13:32:43",
        diningAmenities: "",
        facilities: "",
        generalAmenities: "",
        hotelName: "今天大酒店",
        hotelId: "0010150021609191750394039270vF01657",
        hotelPics: "",
        id: "266731925486084097",
        introEditor: "7天优品酒店（长沙芙蓉广场店）位于长沙交通主动脉芙蓉路与五一路交界处交汇处",
        recreationAmenities: "",
        roomAmenities: "",
        surroundings: "7天优品酒店（长沙芙蓉广场店）位于长沙交通主动脉芙蓉路与五一路交界处",
        telePhone: "0731-82770098",
        updateTime: "2018-11-26 13:32:43",
        hotelPolicy: ""
      })
    }
  },
  data() {
    // return {
    //   roomAmenities: "客房WiFi,空调",
    //   generalAmenities: "停车场,接送机",
    //   facilities: "叫醒服务,24小时前台,行李接送,贵宾服务,结算服务,行李寄存"
    // };
  },
  watch: {

  },
  mounted() {

  },
  computed: {
    roomFacilityList() {
      return this.hotelDetail.roomAmenities.split(",");
    },
    generalFacilityList() {
      return this.hotelDetail.generalAmenities.split(",");
    },
    facilityList() {
      return this.hotelDetail.facilities.split(",");
    },
    hotelName() {
      if (this.hotelDetail?.hotelName?.length > 0) {
        return this.hotelDetail.hotelName;
      }
      return "湖南佳兴世尊酒店";
    }
  },
  methods: {
    closeClick() {
      this.$emit("update:dialogVisible", false);
    },
    close() {
      this.closeClick();
    }
  }
};
</script>

<style lang="scss" scoped>
  .my-dialog {
    .my-body-container {
      padding: 20px;
      .top {
        height: 50px;
        background: #F6FAFD;
        border-bottom: 1px solid #E8ECF2;
        border-radius: 4px 4px 0 0;
        .title {
          padding-left: 19px;
          padding-top: 18px;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
        }
      }
      .content {
        padding: 20px 20px 12px 20px;
        color: #333333;
        font-size:12px;
        font-family:MicrosoftYaHei;
        font-weight:400;
      }
      .body-top-contain {
        border:1px solid #E8ECF2;
        border-radius:4px;
      }
      .body-center-contain {
        margin-top: 20px;
        border:1px solid #E8ECF2;
        border-radius:4px;
        .content-contain {
          margin-top: 18px;
          .item-title {
            padding: 0 20px;
            font-size:12px;
            font-family:MicrosoftYaHei;
            font-weight:bold;
            color: #333333;
          }
          .center-content {
            display: flex;
            flex-wrap: wrap;
            .item-contain {
              display: flex;
              width: 200px;
              padding-bottom: 8px;
              .icon {
                color: #3e90fe;
              }
              .text {
                padding-top: 3px ;
                padding-left: 8px;
                font-size:12px;
                font-family:MicrosoftYaHei;
                font-weight:400;
              }
            }
          }
        }
      }
      .body-bottom-contain {
        margin-top: 20px;
        border:1px solid #E8ECF2;
        border-radius:4px;
        .content {
          padding: 20px !important;
        };
      }
    }
    &/deep/.el-dialog__header {
      box-shadow:0px 3px 7px 0 rgba(0, 0, 0, 0.1);
    }
    &/deep/.el-dialog__body {
      padding: 0 !important;
    }
    &/deep/.el-dialog__footer {
      height: 0;
    }
    &/deep/.el-dialog__title {
      font-weight: bold;
      font-size: 14px;
      color: #333333;
    }
  }
</style>
