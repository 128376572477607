import { hotelService } from "@/api";
// import { request } from "en-js";
// import dayjs from "dayjs";
import { enEqual } from "@/tools/compare";

// 事件列表
const actionMethods = {};

// 请求方法
const requestMethods = {
  // 地图地标搜索
  async requestLocationTips(keywords) {
    const obj = {
      keywords,
      city: this.bookData.cityName
    };
    const rsp = await hotelService.queryLocationTips(obj);
    this.searchList = rsp;
    return this.searchList;
  },
  // 酒店列表
  async requestTripHotelList() {
    const params = {
      arrivalDate: this.bookData.arrivalDate,
      departureDate: this.bookData.departureDate,
      city: this.bookData.city,
      pageNo: this.bookData.pageNo,
      pageSize: this.bookData.pageSize,
      where: this.bookData.where,
      price: this.bookData.price,
      position: this.bookData.position,
      category: this.bookData.category,
      brand: this.bookData.brand,
      sortFields: this.bookData.sortFields,
      sortRank: this.bookData.sortRank,
      lat: this.bookData.lat,
      lon: this.bookData.lon,
      landmarkSearch: this.bookData.landmarkSearch
    };
    const rsp = await hotelService.queryTripHotelList(params);
    if (rsp !== undefined) {
      this.hotelDataSource = rsp;
    }
    return this.hotelDataSource;
  },
  // 商圈 区域
  async requestGetPosition() {
    const params = {
      city: this.bookData.city
    };
    const rsp = await hotelService.queryTripGetPosition(params);
    if (rsp !== undefined) {
      this.positionData = rsp;
    }
  },
  // 酒店品牌
  async requestGetBrand() {
    const params = {
      city: this.bookData.city
    };
    const rsp = await hotelService.queryTripGetBrand(params);
    if (rsp !== undefined) {
      this.hotelBrand = rsp;
    }
  },
  // 酒店详细信息
  async requestHotelDetail(hotelId) {
    const params = {
      hotelId
    };
    const rsp = await hotelService.queryHotelDetail(params);
    this.hotelDetail = rsp;
    return this.hotelDetail;
  },
  // 酒店房型详细信息
  async requestHotelRatePlan(params) {
    const rsp = await hotelService.queryHotelRatePlan(params);
    this.hotelRatePlanList = rsp;
    return this.hotelRatePlanList;
  },
  // 酒店超标检查
  async requestCheckSupplement(params) {
    const rsp = await hotelService.queryCheckSupplement(params);
    this.checkResult = rsp;
    // this.checkResult.result.isExcess = "000";
    // this.checkResult.result.standardFlag = "001";
    return this.checkResult;
  },
  // 酒店节点提交
  async requestSaveTripNode(params) {
    const rsp = await hotelService.querySaveTripNode(params);
    return rsp;
  }
};

const uiMethods = {
  // 酒店房型
  starDescription(item) {
    if (enEqual(item.category, "1")) {
      return "经济型";
    }
    if (enEqual(item.category, "2")) {
      return "二星及其他";
    }
    if (enEqual(item.category, "3")) {
      return "三星及舒适";
    }
    if (enEqual(item.category, "4")) {
      return "四星及高档";
    }
    if (enEqual(item.category, "5")) {
      return "五星及豪华";
    }
    return "";
  }
};

export default {
  data() {
    return {
      // 行程单ID (行程单跳转过来有值)
      tripId: "",
      // 酒店请求参数
      bookData: {
        searchkeywords: "", // 关键字,搜索用
        city: "", // 城市拼音
        enCityCode: "", // 城市河洛code
        arrivalDate: "",
        departureDate: "",
        where: "", // 酒店名、商圈、地标等条件
        price: "", // 价格范围200,200-300,300-500,500
        position: "", // 位置 五一广场,步行街
        category: "", // 星级1-经济型；2-二星及其他；3-三星及舒适；4-四星及高档；5-五星及豪华；如:4,5
        brand: "", // 品牌 七天，汉庭
        lat: "", // 经度 如31.25
        lon: "", // 维度 如121.51
        sortFields: "", // 排序字段如：lowerPrice
        sortRank: "", // 顺序如：ASC DESC
        landmarkSearch: "001", // 是否地标搜索，即选择地标传经纬度搜索。000是、001否
        cityPinYin: "", // 城市拼音
        cityName: "", // 城市名
        addNodeTripId: "", // 新增节点是需要
        pageSize: 20, // 每页数
        pageNo: 1// 当前页码
      },
      // 地点搜索结果
      searchList: [
        // {
        //   address: "黄兴中路",
        //   district: "湖南省长沙市芙蓉区",
        //   lat: "28.1965",
        //   lon: "112.97734",
        //   name: "五一广场"
        // }
      ],
      // 酒店列表数据
      hotelDataSource: {
        records: [
          // {
          //   address: "芙蓉中路一段605号",
          //   baiduLat: 28.197529,
          //   baiduLon: 112.985852,
          //   businessZone: "五一广场/贺龙商圈",
          //   category: "2",
          //   city: "001015002",
          //   code: "0010150021609191750394039270vF01657",
          //   commentsCore: 5,
          //   days: 0,
          //   lowerPrice: "0",
          //   description: "酒店详细描述",
          //   district: "芙蓉区",
          //   enCityId: "C430100",
          //   enName: "7 Days Inn Furong Square",
          //   fullHouse: "000",
          //   id: "266731925486084096",
          //   name: "7天优品酒店(长沙芙蓉广场店)",
          //   phone: "0731-82770098",
          //   thumbnailId: "https://image.517la.com/group1/M00/09/83/rBUzI1vtJ96AOSu-AADkHXjgO0E485.jpg"
          // }
        ],
        hasFirst: "",
        hasLast: "",
        hasNext: "",
        hasPrevious: "",
        pageNo: "",
        pageSize: "",
        total: "",
        totalPages: ""
      },
      // 商圈 区域
      positionData: {
        businessZone: [
          // {
          //   businessZone: "五一广场"
          // }
        ],
        district: [
          // {
          //   district: "岳麓区"
          // }
        ]
      },
      // 酒店品牌
      hotelBrand: {
        brand: [
          // {
          //   brandId: "如家快捷酒店"
          // }
        ]
      },
      // 酒店详情数据
      hotelDetail: {
        airportPickUpService: "",
        brandId: "",
        conferenceAmenities: "",
        createTime: "2018-11-26 13:32:43",
        diningAmenities: "",
        facilities: "",
        generalAmenities: "",
        hotelId: "0010150021609191750394039270vF01657",
        hotelPics: "",
        id: "266731925486084097",
        introEditor: "7天优品酒店（长沙芙蓉广场店）位于长沙交通主动脉芙蓉路与五一路交界处交汇处",
        recreationAmenities: "",
        roomAmenities: "",
        surroundings: "7天优品酒店（长沙芙蓉广场店）位于长沙交通主动脉芙蓉路与五一路交界处",
        telePhone: "0731-82770098",
        updateTime: "2018-11-26 13:32:43"
      },
      // 酒店详细房型数据
      hotelRatePlanList: [
        {
          bedType: "DOUBLE",
          broadnet: "",
          description: "",
          floor: "",
          highlights: "",
          imageUrl: "",
          maxCustomers: "0",
          name: "优品大床房",
          roomLowestRate: "117.00",
          roomTypeId: "0010150021609191750394689196vF03657",
          wifi: "",
          window: "",
          ratePlans: [
            {
              bizType: "",
              breakfastAmount: 0,
              maxCustomers: 2,
              paymentType: "1",
              productName: "不含早（预付）1",
              ratePlanId: "54915330e4e5a0ae4a9d4495baa7af0f1c753385$$6",
              ratePlanOneCost: "117.00",
              ratePlanOneRates: "117.00",
              refundRuleId: "",
              nightlyRates: [
                {
                  availableRooms: 3,
                  cost: "117.00",
                  date: "2020-07-28",
                  salePrice: "117.00",
                  status: true
                }
              ]
            }
          ]
        }
      ],
      // 检验结果
      checkResult: {
        // isAlter:000提醒，001没提醒;
        // content:提醒内容
        hotelAlert: {
          isAlert: "001",
          content: ""
        },
        result: {
          isExcess: "001", // 000超额
          standardMode: "000" // 001超额,可填写事由提交,000超额,禁止提交
        }
      }
    };
  },
  watch: {},
  methods: {
    ...actionMethods,
    ...requestMethods,
    ...uiMethods
  }
};
