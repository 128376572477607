var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hotel-booking-container" },
    [
      _vm.tripShow
        ? _c("en-itinerary", {
            attrs: {
              "dialog-visible": _vm.tripShow,
              "is-new-node": _vm.dataSource.tripId ? false : true,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.tripShow = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.tripShow = $event
              },
              selectTripData: _vm.selectTripData,
              selectUnrelatedTrip: _vm.selectUnrelatedTrip,
              selectCreateNewTrip: _vm.selectCreateNewTrip,
            },
          })
        : _vm._e(),
      _vm.contactShow
        ? _c("en-contacts", {
            attrs: {
              "dialog-visible": _vm.contactShow,
              "exist-list": _vm.existList,
            },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.contactShow = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.contactShow = $event
              },
              getEnContactConfirm: _vm.getEnContactConfirm,
            },
          })
        : _vm._e(),
      _c("en-tips-dialog", {
        attrs: {
          "dialog-visible": _vm.dialogTipsVisible,
          title: "温馨提醒:",
          message: _vm.message,
        },
        on: {
          "update:dialogVisible": function ($event) {
            _vm.dialogTipsVisible = $event
          },
          "update:dialog-visible": function ($event) {
            _vm.dialogTipsVisible = $event
          },
          getEnTipsDialogConfirm: _vm.confirmSubmitTripNodList,
        },
      }),
      _vm.overStandardVisible
        ? _c("overStandardDialog", {
            attrs: { "dialog-visible": _vm.overStandardVisible },
            on: {
              "update:dialogVisible": function ($event) {
                _vm.overStandardVisible = $event
              },
              "update:dialog-visible": function ($event) {
                _vm.overStandardVisible = $event
              },
              getOverStandardMemo: _vm.getOverStandardMemo,
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "header-contain" }, [
        _c("div", { staticClass: "click-title" }, [
          _c(
            "div",
            {
              staticClass: "left-contain",
              on: {
                click: function ($event) {
                  return _vm.$router.go(-1)
                },
              },
            },
            [
              _c("en-icon", {
                staticClass: "back-icon",
                style: { fill: "#A9B5C6" },
                attrs: { name: "fanhui", size: "14px;" },
              }),
              _c("span", { staticClass: "title" }, [_vm._v("酒店预订")]),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "booking-container" }, [
        _c("div", { staticClass: "booking-board" }, [
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "hotel-detail-padding-container" }, [
              _c(
                "div",
                { staticClass: "hotel-detail" },
                [
                  _c("en-image", {
                    staticClass: "hotel-img",
                    attrs: {
                      src: _vm.pics[0],
                      lazy: "",
                      fit: "cover",
                      "preview-src-list": _vm.pics,
                    },
                  }),
                  _c("div", { staticClass: "detail-info" }, [
                    _c("div", { staticClass: "hotel-name" }, [
                      _vm._v(" " + _vm._s(_vm.hotelName) + " "),
                    ]),
                    _c("div", { staticClass: "hotel-label" }, [
                      _vm._v(" " + _vm._s(_vm.categoryName) + " "),
                    ]),
                    _c("div", { staticClass: "hotel-location" }, [
                      _vm._v(" " + _vm._s(_vm.address) + " "),
                    ]),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "time-detail" }, [
                _c("div", { staticClass: "start-time" }, [
                  _c("div", { staticClass: "desc" }, [_vm._v(" 入住时间 ")]),
                  _c("div", { staticClass: "time" }, [
                    _vm._v(" " + _vm._s(_vm.arrivalDate) + " "),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "total-time" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("共" + _vm._s(_vm.totalDay) + "晚"),
                    ]),
                    _c("en-icon", {
                      staticClass: "icon",
                      attrs: { name: "jiantou-jipiao" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "end-time" }, [
                  _c("div", { staticClass: "desc" }, [_vm._v(" 离店时间 ")]),
                  _c("div", { staticClass: "time" }, [
                    _vm._v(" " + _vm._s(_vm.departureDate) + " "),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "footer" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "right-area" },
              [
                _c(
                  "el-popover",
                  {
                    attrs: {
                      "popper-class": "custom-hotel-book-popover",
                      placement: "bottom-end",
                      trigger: "hover",
                    },
                  },
                  [
                    _c("div", { staticClass: "amount-hotel-book-popover" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.numOfRoom) +
                          "间客房 X " +
                          _vm._s(_vm.totalDay) +
                          "晚 " +
                          _vm._s(_vm.breakfastType) +
                          "    ￥" +
                          _vm._s(_vm.totalPrice) +
                          " "
                      ),
                    ]),
                    _c(
                      "div",
                      { attrs: { slot: "reference" }, slot: "reference" },
                      [
                        _vm._v(" 订单总额: "),
                        _c("span", { staticStyle: { color: "#F88529" } }, [
                          _vm._v("￥"),
                        ]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "#F88529",
                              "font-size": "16px",
                            },
                          },
                          [_vm._v(_vm._s(_vm.totalPrice))]
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]),
        _vm._m(1),
        _c("div", { staticClass: "booking-info" }, [
          _c("div", { staticClass: "header" }, [_vm._v(" 入住信息 ")]),
          _c("div", { staticClass: "content" }, [
            _c("div", { staticClass: "line-select" }, [
              _c("div", { staticClass: "left" }, [_vm._v(" 房间数量 ")]),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c("en-select", {
                    staticClass: "select-room-num",
                    staticStyle: { width: "420px" },
                    attrs: { data: _vm.options },
                    model: {
                      value: _vm.numOfRoom,
                      callback: function ($$v) {
                        _vm.numOfRoom = $$v
                      },
                      expression: "numOfRoom",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "line-select" }, [
              _c("div", { staticClass: "left" }, [_vm._v(" 个人信息 ")]),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _vm._l(_vm.personList, function (item) {
                    return _c("personCard", {
                      key: item.key,
                      staticClass: "item",
                      attrs: {
                        name: item.name,
                        "phone-num": item.mobile,
                        "id-card-num": item.idNumber,
                      },
                      on: {
                        deletePersonCard: function ($event) {
                          return _vm.deletePersonCard(item)
                        },
                      },
                    })
                  }),
                  _vm.personList.length < 3 &&
                  _vm.personList.length < _vm.numOfRoom
                    ? _c("personCard", {
                        staticClass: "item",
                        attrs: { type: "addCard" },
                        on: { add: _vm.addPeople },
                      })
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "line-select",
                staticStyle: { "padding-bottom": "20px" },
              },
              [
                _c("div", { staticClass: "left" }, [_vm._v(" 最晚到店 ")]),
                _c(
                  "div",
                  { staticClass: "right" },
                  [
                    _c("en-select", {
                      staticClass: "select-room-num",
                      staticStyle: { width: "420px" },
                      attrs: { data: _vm.arriveTimeOptions },
                      model: {
                        value: _vm.arriveTime,
                        callback: function ($$v) {
                          _vm.arriveTime = $$v
                        },
                        expression: "arriveTime",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "footer-button-contain" },
        [
          _c(
            "en-button",
            { staticClass: "confirm-button", on: { click: _vm.booking } },
            [_vm._v(" 预订 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left-area" }, [
      _c("i", {
        staticClass: "el-icon-warning",
        staticStyle: {
          color: "#F7BF27",
          "font-size": "16px",
          "vertical-align": "-1px",
        },
      }),
      _vm._v(
        " 温馨提示：该订单确认后不可取消或更改，若未入住将收取您全额房费 "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "seaprator-contain" }, [
      _c("div", { staticClass: "seaprator-top" }),
      _c("div", { staticClass: "seaprator-bottom" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }