<template>
  <div class="map-components">
    <div class="amap-wrapper show-hotel-list">
      <div class="zoom-buttons">
        <div class="el-icon-plus block" @click="addZoom"></div>
        <div class="el-icon-minus block" @click="plusZoom"></div>
      </div>
      <el-amap
        ref="map"
        class="amap-box"
        vid="amapDemo"
        :zoom="zoom"
        :center="center"
        :events="events"
      >
        <!-- <el-amap-marker :position="center"></el-amap-marker> -->
        <el-amap-marker
          v-for="item in hotelList"
          :key="item.id"
          :position="item.position"
          :vid="item.hotelName"
          :offset="lableOffset"
          :top-when-click="true"
        >
          <div
            class="marker-container"
            :class="{ active: item.active }"
            @mouseover="showMapHotelDetail(item)"
            @mouseleave="hiddenMapHotelDetail(item)"
          >
            <b>￥ {{ item.price }}</b>
            <div v-if="item.select" class="hotel-show-card">
              <div class="window-container">
                <div class="header">
                  <en-image
                    :src="pics[0]"
                    fit="cover"
                    style="width:320px;height:206px;"
                    lazy
                    :preview-src-list="pics"
                  ></en-image>
                </div>
                <div class="content">
                  <div class="title">
                    {{ item.hotelName }}
                  </div>
                  <div class="label">
                    {{ starDescription(item) }}
                  </div>
                  <div class="location">
                    {{ item.address }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-amap-marker>
      </el-amap>
    </div>
    <div class="hotel-list">
      <div class="header">
        周边酒店
      </div>
      <el-divider></el-divider>
      <div class="hotel-show-list">
        <div v-for="(item, index) in hotelList" :key="index" class="hotel-card">
          <hotelShowCardMin
            :hotel-info="item.hotelInfo"
            :is-full="item.isFull"
            :price="Number(item.price)"
            :address="item.address"
            :hotelName="item.hotelName"
            :label="starDescription(item)"
            :hotel-id="item.code"
            :arrival-date="showMapQuery.hotelDataSource.arrivalDate"
            :departure-date="showMapQuery.hotelDataSource.departureDate"
            @mouseenter.native="select(index)"
            @mouseleave.native="unselect(index)"
            @toBookingPage="toBookingPage"
          ></hotelShowCardMin>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";
import hotelShowCardMin from "./hotel-show-card-min";
import showMapQuery from "../data/showMapQuery";
import hotelData from "../hotelData";

export default {
  name: "MapQuery",
  components: { hotelShowCardMin },
  props: {

  },
  data() {
    return {
      showMapQuery,
      pics: ["http://cdn.enfry.com/hotel_test_need_delete.png"],
      zoom: 16,
      center: [112.982279, 28.19409],
      price: 310,
      lableOffset: [-21, -39], // 标签的箭头偏移
      hotelList: [],
      events: {
        init: (o) => {
          // console.log(o.getCenter(), 11111);
          const { lng, lat } = o.getCenter();
          this.center = [lng, lat];
          // console.log(this.$refs.map.$$getInstance());
          // o.getCity(result => {
          // console.log(result);
          // });
        },
        click: () => {
          this.hotelList.forEach((label) => {
            label.active = false;
            label.select = false;
          });
          // this.center = [ 112.980840, 31.980840 ];
        },
        zoomend: () => {
          // 获取当前缩放zoom值
          const currentZoom = this.$refs.map.$$getInstance().getZoom();
          this.zoom = currentZoom;
          // console.log(currentZoom);
        }
      }
    };
  },
  activated() {
    const list = [];
    for (let i = 0; i < showMapQuery.hotelDataSource?.records?.length; i++) {
      const obj = showMapQuery.hotelDataSource?.records[i];
      list.push(
        {
          hotelInfo: obj,
          position: [obj.baiduLon, obj.baiduLat],
          price: obj.lowerPrice ? obj.lowerPrice : 0,
          hotelName: obj.name,
          category: obj.category,
          address: obj.address,
          code: obj.code,
          isFull: obj.fullHouse === "000",
          select: false, // 选中展示地图卡片
          active: false // 选中高亮酒店地图标签的边框
        }
      );
    }
    this.hotelList = [...list];
  },
  mixins: [hotelData],
  computed: {},
  methods: {
    addZoom() {
      if (this.zoom < 18) {
        this.zoom++;
      }
    },
    plusZoom() {
      if (this.zoom > 3) {
        this.zoom--;
      }
    },
    selectMapHotelLabel(item) {
      // 高亮某个地图酒店标签
      this.hotelList.forEach((label) => { label.active = false; });
      item.active = true;
    },
    showMapHotelDetail(item) {
      // 显示某个label的详细地图卡片
      this.selectMapHotelLabel(item);
      this.hotelList.forEach((label) => { label.select = false; });
      item.select = true;
    },
    hiddenMapHotelDetail() {
      // 隐藏某个label的详细地图卡片
      this.hotelList.forEach((label) => { label.active = false; });
      this.hotelList.forEach((label) => { label.select = false; });
    },
    select(index) {
      this.hotelList[index].active = true;
      this.center = this.hotelList[index].position;
    },
    unselect(index) {
      this.hotelList[index].active = false;
    },
    clickCard(index) {
      this.hotelList[index].select = !this.hotelList[index].select;
    },
    // 跳转预订页面
    toBookingPage(val) {
      const hotelInfo = cloneDeep(val);
      hotelInfo.arrivalDate = this.showMapQuery.hotelDataSource.arrivalDate;
      hotelInfo.departureDate = this.showMapQuery.hotelDataSource.departureDate;
      this.$router.push({
        path: "/business-travel/hotel/booking",
        query: hotelInfo
      });
    }
  }
};
</script>
<style scoped lang="scss">
.map-components {
  height: 100%;
  width: 100%;
  display: flex;
  .amap-wrapper {
    height: 100%;
    position: relative;
    transition: all 1s;
    background-color: #fff;
    &.show-hotel-list {
      width: calc(100% - 680px);
      min-width: 50%;
    }
    .amap-box {
      height: 100%;
      width: 100%;
      .marker-container {
        position: relative;
        width: 61px;
        height: 32px;
        line-height: 30px;
        text-align: center;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(232, 236, 242, 1);
        box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.04);
        border-radius: 4px;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: rgba(70, 148, 223, 1);
        &.active {
          border: 1px solid #3e90fe;
        }
        &:after {
          content: "";
          width: 10px;
          height: 10px;
          border-top: 1px solid rgba(232, 236, 242, 1);
          border-right: 1px solid rgba(232, 236, 242, 1);
          background-color: #fff;
          transform: rotateZ(135deg);
          position: absolute;
          left: 15px;
          /*left=90时候 是不算border的 90+4是正确的*/
          top: 25px;
          /*left=2才是要求的距离顶部为0*/
        }
        &.active:after {
          border-top: 1px solid #3e90fe;
          border-right: 1px solid #3e90fe;
        }
        .hotel-show-card {
          position: absolute;
          left: -138px;
          top: -360px;
          z-index: 120;
          &:after {
            content: "";
            width: 18px;
            height: 18px;
            border-top: 1px solid rgba(232, 236, 242, 1);
            border-right: 1px solid rgba(232, 236, 242, 1);
            background-color: #fff;
            transform: rotateZ(135deg);
            position: absolute;
            left: 151px;
            /*left=90时候 是不算border的 90+4是正确的*/
            top: 334px;
            /*left=2才是要求的距离顶部为0*/
          }
          .window-container {
            width: 320px;
            height: 344px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(232, 236, 242, 1);
            box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.04);
            border-radius: 4px;
            overflow: hidden;
            text-align: left;

            .header {
              height: 206px;
              background: gray;
            }
            .content {
              padding: 0 5px;
              .title {
                font-size: 16px;
                font-family: MicrosoftYaHei;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                margin-top: 15px;
              }
              .label {
                font-size: 12px;
                font-family: MicrosoftYaHei;
                font-weight: 400;
                color: rgba(247, 191, 39, 1);
                background: #fefaef;
                border-radius: 4px;
                height: 24px;
                line-height: 24px;
                margin-top: 13px;
                display: inline-block;
                padding: 0 8px;
              }
              .location {
                font-size: 12px;
                font-family: MicrosoftYaHei;
                font-weight: 400;
                color: rgba(145, 161, 183, 1);
                margin-top: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
              }
            }
          }
        }
      }
    }
    .zoom-buttons {
      width: 40px;
      height: 80px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 99;
      .block {
        height: 40px;
        width: 40px;
        line-height: 40px;
        border: 1px solid rgba(232, 236, 242, 1);
        display: block;
        text-align: center;
      }
    }
  }
  .hotel-list {
    border-left: 1px solid rgba(232, 236, 242, 1);
    padding: 20px;
    height: 100%;
    width: 50%;
    max-width: 680px;
    &.hidden {
      width: 0;
    }
    .header {
      height: 20px;
      font-size: 14px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
      text-align: left;
      line-height: 20px;
    }
    .hotel-show-list {
      height: calc(100% - 50px);
      overflow: auto;
      .hotel-card {
        cursor: pointer;
        &:hover{
          background-color: #f5f8fc;
        }
      }
    }
  }
}
</style>
