var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "amap-wrapper" },
    [
      _c("div", { staticClass: "header" }, [
        _c("div", { staticClass: "title" }, [_vm._v(" 查看地图 ")]),
        _c("div", {
          staticClass: "icon el-icon-close",
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.close.apply(null, arguments)
            },
          },
        }),
      ]),
      _c(
        "el-amap",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.AMapLoaded,
              expression: "AMapLoaded",
            },
          ],
          ref: "detailMap",
          staticClass: "amap-box",
          attrs: {
            vid: "detailMap",
            zoom: _vm.zoom,
            center: _vm.showConfig.position,
            events: _vm.events,
          },
        },
        [
          _c("el-amap-marker", {
            attrs: { position: _vm.showConfig.position },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }