<!--
 * @Author: Fenngding
 * @Date: 2019-10-10 10:00:34
 * @LastEditors: Fengding
 * @LastEditTime: 2019-10-21 14:21:14
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div v-en-loading="enLoading" class="hotel-show-card">
    <div class="card">
      <en-image
        :src="pics[0]"
        fit="cover"
        class="img"
        lazy
        :preview-src-list="pics"
      ></en-image>
      <div class="content">
        <div class="left">
          <div class="title" @click="hotelDetailsAction">
            {{ hotelName }}
          </div>
          <div class="label">
            {{ label }}
          </div>
          <div class="location">
            {{ address }}
          </div>
          <div class="map-area" @click="showMap">
            <div class="icon el-icon-location"></div>
            <div class="title">
              查看地图
            </div>
          </div>
        </div>
        <div class="right">
          <div v-if="!isFull" class="price">
            ￥ <span style="font-size:18px">{{ lowerPrice }}</span> 起
          </div>
          <!--:loading="enLoading"-->
          <en-button
            v-if="!isFull && !showDetailRoomList"
            class="button"
            @click="showDetail"
          >
            预订
          </en-button>
          <div
            v-if="showDetailRoomList"
            class="button pick-up"
            @click="pickUpDetail"
          >
            收起
          </div>
          <div
            v-if="isFull && !showDetailRoomList"
            class="button full"
            @click="showDetail"
          >
            已满房
          </div>
        </div>
      </div>
    </div>
    <en-collapse-area>
      <hotelDetail
        v-if="showDetailRoomList"
        :roomInfo="roomInfo"
        :hotel-info="hotelInfo"
        class="hotel-detail-container"
        @toBookingPage="toBookingPage"
      ></hotelDetail>
    </en-collapse-area>

    <hotelDetails
      v-if="hotelDetailsShow"
      :dialog-visible.sync="hotelDetailsShow"
      :hotel-detail="hotelCurrentDetail"
    ></hotelDetails>
  </div>
</template>
<script>
import { request } from "en-js";
import { cloneDeep } from "lodash";
import hotelDetail from "./hotel-detail";
import showConfig from "../data/showDetailMap";
import hotelData from "../hotelData";
import hotelDetails from "./hotelDetails";

export default {
  name: "",
  components: { hotelDetail, hotelDetails },
  props: {
    hotelInfo: {
      type: Object,
      default: () => ({})
    },
    isFull: {
      type: Boolean,
      default: false
    },
    hotelName: {
      type: String,
      default: "北京鹏润国际大酒店"
    },
    label: {
      type: String,
      default: "舒适性酒店"
    },
    address: {
      type: String,
      default: "位于亮马桥区域，朝阳区区霄云路26号，靠近新恒基国际大厦"
    },
    lowerPrice: {
      type: Number,
      default: 0
    },
    lat: {
      type: Number,
      default: 28.0
    },
    lon: {
      type: Number,
      default: 112.0
    },
    hotelId: {
      type: String,
      default: ""
    },
    arrivalDate: {
      type: String,
      default: "2020-01-01"
    },
    departureDate: {
      type: String,
      default: "2020-01-02"
    },
    pics: {
      type: Array,
      default: () => [
        "http://cdn.enfry.com/hotel_test_need_delete.png",
        "http://cdn.enfry.com/hotel_test_need_delete.png"
      ]
    }
  },
  data() {
    return {
      showConfig,
      enLoading: false,
      showDetailRoomList: false,
      roomInfo: [],
      hotelDetailsShow: false,
      hotelCurrentDetail: {}
    };
  },
  watch: {
    // hotelDetailsShow(val) {
    //   console.log(val);
    // }
  },
  mixins: [hotelData],
  computed: {
    photo() {
      return `url(${this.pic})`;
    }
  },
  methods: {
    showMap() {
      // 点击地图图标显示地图
      this.showConfig.position = [this.lon, this.lat];
      this.showConfig.show = true;
    },
    @request(true, "enLoading")
    async showDetail() {
      // 点击card的预定按钮显示详细信息
      const roomInfo = await this.requestHotelRatePlan({
        hotelId: this.hotelId,
        arrivalDate: this.arrivalDate,
        departureDate: this.departureDate
      });
      this.roomInfo = [...roomInfo];
      this.showDetailRoomList = true;
    },
    pickUpDetail() {
      this.showDetailRoomList = false;
    },
    toBookingPage(val) {
      this.$emit("toBookingPage", val);
    },
    @request(true)
    async hotelDetailsAction() {
      const detail = await this.requestHotelDetail(this.hotelId);
      this.hotelCurrentDetail = cloneDeep(detail);
      this.hotelCurrentDetail.hotelName = this.hotelName;
      // console.log(this.hotelCurrentDetail);
      this.hotelDetailsShow = true;
    }
  }
};
</script>
<style scoped lang="scss">
.hotel-show-card {
  transition: height 1s;
  .card {
    min-height: 180px;
    margin-bottom: 16px;
    border: 1px solid rgba(232, 236, 242, 1);
    border-radius: 4px;
    display: flex;
    position: relative;
    overflow: hidden;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.04);
    .img {
      height: 180px;
      width: 180px;
      background: #e9ecf2;
    }
    .content {
      width: calc(100% - 180px);
      display: flex;
      padding: 20px;
      text-align: left;
      .left {
        border-right: 1px solid rgba(232, 236, 242, 1);
        width: calc(100% - 220px);
        .title {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
        }
        .label {
          height: 24px;
          background: #fefaef;
          margin-top: 13px;
          display: inline-block;
          padding: 0 8px;
          border-radius: 4px;
          line-height: 24px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(247, 191, 39, 1);
        }
        .location {
          margin-top: 13px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(145, 161, 183, 1);
        }
        .map-area {
          margin-top: 43px;
          display: flex;
          align-items: center;
          cursor: pointer;
          -webkit-user-select: none;
          .icon {
            color: #3e90fe;
          }
          .title {
            font-size: 12px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: rgba(145, 161, 183, 1);
          }
        }
      }
      .right {
        width: 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .price {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          font-weight: bold;
          color: rgba(247, 133, 40, 1);
          margin-bottom: 16px;
        }
        /deep/ .button.en-btn-normal {
          width: 72px;
          line-height: 14px;
        }
        .button {
          width: 72px;
          height: 32px;
          text-align: center;
          font-size: 12px;
          line-height: 32px;
          border-radius: 4px;
          -webkit-user-select: none;
          cursor: pointer;
          &.full {
            background: #f0f1f2;
            color: rgba(99, 108, 120, 1);
          }
          &.pick-up {
            color: #3e90fe;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(97, 164, 227, 1);
            line-height: 32px;
          }
        }
      }
    }
  }
}
</style>
