<template>
  <div class="amap-wrapper">
    <div class="header">
      <div class="title">
        查看地图
      </div>
      <div class="icon el-icon-close" @click.stop="close"></div>
    </div>
    <el-amap
      v-show="AMapLoaded"
      ref="detailMap"
      class="amap-box"
      vid="detailMap"
      :zoom="zoom"
      :center="showConfig.position"
      :events="events"
    >
      <el-amap-marker :position="showConfig.position"></el-amap-marker>
    </el-amap>
  </div>
</template>
<script>
import { lazyAMapApiLoaderInstance } from "vue-amap";
import showConfig from "../data/showDetailMap";

export default {
  name: "MapQuery",
  props: {},
  data() {
    return {
      showConfig,
      AMapLoaded: false,
      zoom: 16,
      // center: [112.982279, 28.19409],
      price: 310,
      lableOffset: [-21, -39], // 标签的箭头偏移
      windowOffset: [-160, -348], // 窗体的箭头偏移
      events: {
        init: (o) => {
          // console.log(o.getCenter(), 11111);
          const { lng, lat } = o.getCenter();
          this.center = [lng, lat];
          // console.log(this.$refs.detailMap.$$getInstance());
          o.getCity((result) => {
            console.log(result);
          });
        },
        click: (e) => {
          console.log(e);
          // console.log(this.center);
          // this.center = [ 112.980840, 31.980840 ];
        }
      }
    };
  },
  computed: {},
  mounted() {
    lazyAMapApiLoaderInstance.load().then(() => {
      localStorage.removeItem("_AMap_raster");
      this.AMapLoaded = true;
    });
  },
  methods: {
    close() {
      this.showConfig.show = false;
    }
  }
};
</script>
<style scoped lang="scss">
.amap-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 120px;
  z-index: 100;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  overflow: hidden;
  background-color: #fff;
  .header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 1);
    line-height: 49px;
    border-bottom: 1px solid rgba(232, 236, 242, 1);
    padding: 0 20px;
    .title {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      color: rgba(51, 51, 51, 1);
    }
    .icon {
      color: #a9b5c6;
    }
  }
  .amap-box {
    height: calc(100% - 50px);
    width: 100%;
    .marker-container {
      position: relative;
      width: 61px;
      height: 32px;
      line-height: 30px;
      text-align: center;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(232, 236, 242, 1);
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: bold;
      color: rgba(70, 148, 223, 1);
      &.active {
        border: 1px solid #3e90fe;
      }
      &:after {
        content: "";
        width: 10px;
        height: 10px;
        border-top: 1px solid rgba(232, 236, 242, 1);
        border-right: 1px solid rgba(232, 236, 242, 1);
        background-color: #fff;
        transform: rotateZ(135deg);
        position: absolute;
        left: 15px;
        /*left=90时候 是不算border的 90+4是正确的*/
        top: 25px;
        /*left=2才是要求的距离顶部为0*/
      }
      &.active:after {
        border-top: 1px solid #3e90fe;
        border-right: 1px solid #3e90fe;
      }
    }

    .window-container {
      width: 320px;
      height: 334px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(232, 236, 242, 1);
      box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.04);
      border-radius: 4px;
      overflow: hidden;
      text-align: left;
      &:after {
        content: "";
        width: 18px;
        height: 18px;
        border-top: 1px solid rgba(232, 236, 242, 1);
        border-right: 1px solid rgba(232, 236, 242, 1);
        background-color: #fff;
        transform: rotateZ(135deg);
        position: absolute;
        left: 151px;
        /*left=90时候 是不算border的 90+4是正确的*/
        top: 324px;
        /*left=2才是要求的距离顶部为0*/
      }
      .header {
        height: 206px;
        background: gray;
      }
      .content {
        padding: 0 5px;
        .title {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          margin-top: 15px;
        }
        .label {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(247, 191, 39, 1);
          background: #fefaef;
          border-radius: 4px;
          height: 24px;
          line-height: 24px;
          margin-top: 13px;
          display: inline-block;
          padding: 0 8px;
        }
        .location {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(145, 161, 183, 1);
          margin-top: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
        }
      }
    }
  }
  .zoom-buttons {
    width: 40px;
    height: 81px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
    .block {
      height: 40px;
      width: 40px;
      line-height: 40px;
      border: 1px solid rgba(232, 236, 242, 1);
      display: block;
    }
  }
}
</style>
