var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "chooseItem",
      staticClass: "chooseItem-container",
      class: { visible: _vm.visible },
    },
    [
      _c(
        "el-popover",
        {
          attrs: {
            placement: "bottom",
            "popper-class": "chooseItem-card",
            width: _vm.width,
            "visible-arrow": false,
            trigger: "click",
          },
          model: {
            value: _vm.visible,
            callback: function ($$v) {
              _vm.visible = $$v
            },
            expression: "visible",
          },
        },
        [
          _c(
            "div",
            { staticClass: "item-container", style: { width: _vm.width } },
            _vm._l(_vm.options, function (option) {
              return _c(
                "div",
                {
                  key: option.value,
                  staticClass: "option",
                  class: {
                    active: _vm.value.find(
                      (item) => item.value === option.value
                    ),
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.choose(option)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(option.label) + " ")]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticClass: "chooseItem-title",
              class: { visible: _vm.visible },
              attrs: { slot: "reference" },
              slot: "reference",
            },
            [
              _vm._v(" " + _vm._s(_vm.name) + " "),
              _c("i", {
                staticClass: "el-icon-arrow-down",
                class: { visible: _vm.visible },
              }),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }