<template>
  <div class="hotel-detail-container">
    <div class="header-area">
      <div class="room-type">
        房型
      </div>
      <div class="room-name"></div>
      <div class="room-breakfast">
        <el-checkbox :disabled="isAllFull" v-model="filter.breakfastIncluded">
          有早
        </el-checkbox>
      </div>
      <div class="room-price">
        价格
      </div>
      <div class="room-booking">
        <el-checkbox :disabled="isAllFull" v-model="filter.filterFull">
          有房
        </el-checkbox>
      </div>
    </div>
    <div class="room-detail-area">
      <div v-if="isAllFull" style="padding: 10px 0;text-align: center;color: #aeaeae;font-size: 14px;">
        已满房
      </div>
      <div
        v-for="(room, index) in roomList"
        :key="index + room.roomTypeId"
        class="border"
      >
        <div v-if="room.ratePlans.length" class="room-row">
          <div class="room-type">
            <div class="room-type-name">
              {{ room.name }}
            </div>
          </div>
          <div class="room-list-container">
            <div
              v-for="(item, key) in room.ratePlans"
              :key="key + item.ratePlanId"
              class="border"
            >
              <div
                v-if="room.showAll || (!room.showAll && key <= 2)"
                class="single-room-detail"
              >
                <div class="room-name">
                  {{ item.productName }}
                </div>
                <div class="room-breakfast">
                  {{ item.breakfastAmount === 1 ? "有早":(item.breakfastAmount === 0 ? "无早":"双早") }}
                </div>
                <div class="room-price">
                  ￥<span class="price-num">{{ item.ratePlanOneRates }}</span>
                </div>
                <div class="room-booking">
                  <en-button
                    class="booking-button"
                    @click="gotoBookingPage(room,item)"
                  >
                    预定
                  </en-button>
                </div>
              </div>
            </div>
            <div
              v-if="room.ratePlans.length > 3"
              class="loadmore-room-button"
              @click="toggleShowAllRoom(room)"
            >
              更多房型
              <i
                class="el-icon-arrow-down icon"
                :class="{ 'is-reverse': room.showAll }"
              ></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from "lodash";

export default {
  name: "HotelDetail",
  components: {},
  props: {
    hotelInfo: {
      type: Object,
      default: () => ({})
    },
    roomInfo: {
      type: Array,
      default: () => [
        {
          bedType: "BIG_DOUBLE",
          broadnet: "",
          description: "",
          floor: "",
          highlights: "",
          maxCustomers: "0",
          name: "精选特优房",
          roomLowestRate: "175.00",
          roomTypeId: "0010150021804022228564895620XXXX657",
          wifi: "",
          window: "",
          ratePlans: [
            {
              bizType: "",
              breakfastAmount: 0,
              maxCustomers: 0,
              nightlyRates: [],
              paymentType: "1",
              productName: "精选大床房",
              ratePlanId: "158479372$$5",
              ratePlanOneCost: "275.00",
              ratePlanOneRates: "275.00",
              refundRuleId: ""
            },
            {
              bizType: "",
              breakfastAmount: 0,
              maxCustomers: 0,
              nightlyRates: [],
              paymentType: "1",
              productName: "精选特优房",
              ratePlanId: "158479372$$5",
              ratePlanOneCost: "175.00",
              ratePlanOneRates: "175.00",
              refundRuleId: ""
            }
          ],
          imageUrl: "",
          pics: [
            "http://cdn.enfry.com/hotel_test_need_delete.png",
            "http://cdn.enfry.com/hotel_test_need_delete.png"
          ]
        }
      ]
    }
  },
  data() {
    return {
      breakfastCode: ["无早", "单早", "双早"],
      filter: {
        breakfastIncluded: false,
        filterFull: false
      }
    };
  },
  computed: {
    roomList() {
      const list = cloneDeep(this.roomInfo);
      list.forEach((room) => {
        room.ratePlans = room?.ratePlans.filter((roomType) => {
          const { breakfastIncluded } = this.filter;
          return (!breakfastIncluded || breakfastIncluded === (roomType?.breakfastAmount !== 0));
        });
      });
      return list;
    },
    isAllFull() {
      return this.roomList.length === 0;
    }
  },
  methods: {
    toggleShowAllRoom(room) {
      room.showAll = !room.showAll;
      this.$forceUpdate();
    },
    gotoBookingPage(room, val) {
      const hotelInfo = cloneDeep(this.hotelInfo);
      const roomInfo = cloneDeep(room);
      roomInfo.ratePlan = val;
      hotelInfo.roomInfo = roomInfo;
      this.$emit("toBookingPage", hotelInfo);
    }
  }
};
</script>
<style scoped lang="scss">
@mixin cell($width, $height) {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  width: $width;
  text-align: left;
  height: $height;
  padding: 4px;
  line-height: 41px;
}
.hotel-detail-container {
  width: 100%;
  // height: 100%;
  margin-top: -8px;
  margin-bottom: 8px;
  // height: 438px;
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid rgba(232, 236, 242, 1);
  border-bottom: 0 solid #fff;
  .header-area {
    height: 50px;
    background: rgba(246, 250, 253, 1);
    border-bottom: 1px solid rgba(232, 236, 242, 1);
    display: flex;
    .room-type {
      @include cell(20%, 50px);
      padding-left: 12px;
    }
    .room-name {
      @include cell(35%, 50px);
    }
    .room-breakfast {
      @include cell(15%, 50px);
      /deep/ .el-checkbox__label {
        color: rgba(51, 51, 51, 1);
      }
    }
    .room-price {
      @include cell(15%, 50px);
    }
    .room-booking {
      @include cell(15%, 50px);
      /deep/ .el-checkbox__label {
        color: rgba(51, 51, 51, 1);
      }
    }
  }
  .room-detail-area {
    height: calc(100% - 50px);
    overflow: auto;
    .room-row {
      display: flex;
      .room-type {
        display: flex;
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        width: 20%;
        text-align: left;
        padding: 4px;
        line-height: 20px;
        border-right: 1px solid rgba(232, 236, 242, 1);
        border-bottom: 1px solid rgba(232, 236, 242, 1);
        &:last-of-type {
          border-bottom: 0;
        }
        .room-type-name {
          margin-left: 12px;
          padding-top: 10px;
        }
      }
      .room-list-container {
        // border-bottom: 1px solid rgba(232, 236, 242, 1);
        width: 80%;
        .border {
          // border-bottom: 1px solid rgba(232, 236, 242, 1);
          &:last-of-type {
            border-bottom: 0;
          }
          // &:nth-last-child{

          // }
          .single-room-detail {
            border-bottom: 1px solid rgba(232, 236, 242, 1);
            display: flex;
            .room-name {
              @include cell(43.75%, 47px);
              color: #636c78;
            }
            .room-breakfast {
              @include cell(18.75%, 47px);
              padding-left: 37px;
              color: #636c78;
            }
            .room-price {
              @include cell(18.75%, 47px);
              padding-left: 5px;
              color: #f78528;
              .price-num {
                font-size: 14px;
              }
            }
            .room-booking {
              @include cell(18.75%, 47px);
              .booking-button {
                width: 72px;
                height: 32px;
                background: rgba(70, 148, 223, 1);
                border-radius: 4px;
                font-size: 12px;
                font-family: MicrosoftYaHei;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                text-align: center;
              }
              .full-button {
                width: 72px;
                height: 32px;
                border-radius: 4px;
                font-family: MicrosoftYaHei;
                font-weight: 400;
                text-align: center;
                border: 0;
                background: #f0f1f2 !important;
                color: #636c78 !important;
              }
            }
          }
        }
        .loadmore-room-button {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(70, 148, 223, 1);
          line-height: 32px;
          text-align: center;
          height: 32px;
          background: rgba(251, 251, 251, 1);
          border-bottom: 1px solid rgba(232, 236, 242, 1);
          cursor: pointer;
          -webkit-user-select: none;
          .icon {
            transition: all 0.5s;
            &.is-reverse {
              transform: rotateZ(180deg);
            }
          }
        }
      }
    }
  }
}
</style>
