var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dialog-alert-contain" },
    [
      _c(
        "en-dialog",
        {
          staticClass: "my-dialog",
          attrs: {
            visible: _vm.dialogVisible,
            title: "酒店详情",
            "en-fullscreen": "",
            "en-body-scroll": "",
          },
          on: { close: _vm.close },
        },
        [
          _c("div", { staticClass: "my-body-container" }, [
            _c("div", { staticClass: "body-top-contain" }, [
              _c("div", { staticClass: "top" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.hotelName)),
                ]),
              ]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.hotelDetail.introEditor)),
              ]),
            ]),
            _c("div", { staticClass: "body-center-contain" }, [
              _c("div", { staticClass: "top" }, [
                _c("div", { staticClass: "title" }, [_vm._v("酒店设施")]),
              ]),
              _c("div", { staticClass: "content-contain" }, [
                _c("div", { staticClass: "item-title" }, [_vm._v("网络")]),
                _vm.roomFacilityList.length > 0
                  ? _c(
                      "div",
                      { staticClass: "content center-content" },
                      _vm._l(_vm.roomFacilityList, function (item) {
                        return _c("div", { key: item }, [
                          item.length > 0
                            ? _c(
                                "div",
                                { staticClass: "item-contain" },
                                [
                                  _c("en-icon", {
                                    staticClass: "icon",
                                    attrs: { size: "16", name: "yixuan" },
                                  }),
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v(_vm._s(item)),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _c("div", { staticClass: "item-title" }, [_vm._v("交通服务")]),
                _vm.generalFacilityList.length > 0
                  ? _c(
                      "div",
                      { staticClass: "content center-content" },
                      _vm._l(_vm.generalFacilityList, function (item) {
                        return _c("div", { key: item }, [
                          item.length > 0
                            ? _c(
                                "div",
                                { staticClass: "item-contain" },
                                [
                                  _c("en-icon", {
                                    staticClass: "icon",
                                    attrs: { size: "16", name: "yixuan" },
                                  }),
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v(_vm._s(item)),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
                _c("div", { staticClass: "item-title" }, [_vm._v("前台服务")]),
                _vm.facilityList.length > 0
                  ? _c(
                      "div",
                      { staticClass: "content center-content" },
                      _vm._l(_vm.facilityList, function (item) {
                        return _c("div", { key: item }, [
                          item.length > 0
                            ? _c(
                                "div",
                                { staticClass: "item-contain" },
                                [
                                  _c("en-icon", {
                                    staticClass: "icon",
                                    attrs: { size: "16", name: "yixuan" },
                                  }),
                                  _c("div", { staticClass: "text" }, [
                                    _vm._v(_vm._s(item)),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                        ])
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "body-bottom-contain" }, [
              _c("div", { staticClass: "top" }, [
                _c("div", { staticClass: "title" }, [_vm._v("酒店政策")]),
              ]),
              _c("div", { staticClass: "content" }, [
                _vm._v(_vm._s(_vm.hotelDetail.hotelPolicy)),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }