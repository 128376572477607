<!--
 * @Author: Fenngding
 * @Date: 2019-10-10 10:00:34
 * @LastEditors: zhulin
 * @LastEditTime: 2021-08-27 16:37:44
 * @Email: fenngding@live.com
 * @Description:
 -->
<template>
  <div class="query-content-container"  v-en-loading="!isRequestListEnd">
    <div class="date-content-contain">
      <div class="query-area">
        <div class="query-title">
          <en-city
            :value="query.cityInfo"
            class="query-city"
            type="hotel"
            placeholder="入住城市"
            @input="cityInfoChanged"
            show-icon
          ></en-city>
          <div class="query-date">
            <el-date-picker
              v-model="query.bookingDate"
              type="daterange"
              style="width:340px;"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
            <div class="show-total-day">
              共<span style="color:#3e90fe"> {{ totalDay }} </span>晚
            </div>
          </div>
          <div class="query-location-contain">
            <el-select
              v-model="query.keywords"
              value-key="name"
              prefix-icon="el-icon-map-location"
              class="query-location"
              clearable
              filterable
              remote
              reserve-keyword
              placeholder="酒店名/地标/商圈"
              :remote-method="remoteMethod"
              :loading="loading"
              @change="onChange"
            >
              <el-option
                v-for="item in seachOptions"
                :key="item.value"
                :label="item.label"
                :value="item"
              >
                <en-icon
                  name="ditu-jiudian"
                  size="16px"
                  style="fill:#ff00ff;float:left;margin-top:7px;margin-right:5px;"
                ></en-icon>
                <span style="float:left">{{ item.label }}</span>
              </el-option>
            </el-select>

            <en-icon
              name="ditu-jiudian"
              size="16px"
              class="icon"
              :style="{ fill: '#A9B5C6' }"
            ></en-icon>
          </div>

          <en-button class="query-button" type="primary" @click="searchClick">
            搜索
          </en-button>
        </div>
        <div class="query-condition">
          <en-choose-card
            ref="hotelChooseCard"
            :positionData="positionData"
            :hotelBrand="hotelBrand"
            @filterParams="filterChangeParams"
            class="query-condition-container"
          >
          </en-choose-card>
        </div>
      </div>

      <!-- 中间黑色8px圆角分隔 -->
      <div class="seaprator-contain">
        <div class="seaprator-top"></div>
        <div class="seaprator-bottom"></div>
      </div>

      <div class="query-hotel-list">
        <div class="query-hotel-top-area">
          <div class="title">
            酒店预订
          </div>
          <div class="content-area">
            <div
              class="sorting-button"
              :class="{ active: sortType === 0 }"
              @click="recommendSort"
            >
              推荐排序
            </div>
            <div class="price-area">
              <div class="title">
                价格
              </div>
              <div class="icon-area">
                <div
                  class="el-icon-caret-top block"
                  :class="{ active: sortType === 1 }"
                  @click="sortPriceUp"
                ></div>
                <div
                  class="el-icon-caret-bottom block"
                  :class="{ active: sortType === 2 }"
                  @click="sortPriceDown"
                ></div>
              </div>
            </div>
            <div class="map-button" @click="gotoMap">
              <en-icon
                name="ditu-jiudian"
                size="16px"
                :style="{ fill: '#179EDA' }"
              ></en-icon>
              <span class="title">
              地图
            </span>
            </div>
          </div>
        </div>
        <div class="query-hotel-list-area" :class="{loading: isRequestListEnd && !isFilterRequestEnd}" v-en-loading="isRequestListEnd && !isFilterRequestEnd">
          <div v-if="hotelDataSource.records.length === 0 && isRequestListEnd">很抱歉，没有找到符合条件的酒店哦！</div>
          <hotelShowCard
            v-for="item in hotelDataSource.records"
            :key="item.id"
            :is-full="item.fullHouse === '000'"
            :lower-price="Number(item.lowerPrice)"
            :hotel-name="item.name"
            :hotel-id="item.code"
            :label="starDescription(item)"
            :address="item.address"
            :pics="[item.thumbnailId]"
            :lat="item.baiduLat"
            :lon="item.baiduLon"
            :arrival-date="bookData.arrivalDate"
            :departure-date="bookData.departureDate"
            :hotel-info="item"
            @toBookingPage="toBookingPage"
          ></hotelShowCard>
        </div>
      </div>
    </div>

    <en-pagination class="page-container" :page-model="hotelDataSource" :disabled="loading" @change="changePage"></en-pagination>
    <transition name="down">
      <hotelDetailMap v-if="hotelMapShowConfig.show"></hotelDetailMap>
    </transition>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { request } from "en-js";
import { cloneDeep } from "lodash";
import { mapMutations, mapGetters } from "vuex";
import enChooseCard from "../components/en-choose-card";
import enCity from "../components/en-city";
import hotelMapShowConfig from "./data/showDetailMap";
import showMapQuery from "./data/showMapQuery";
import hotelData from "./hotelData";
import hotelShowCard from "./components/hotel-show-card";
import hotelDetailMap from "./components/hotel-detail-map";

export default {
  name: "HotelList",
  components: {
    hotelShowCard,
    hotelDetailMap,
    enChooseCard,
    enCity
  },
  props: {},
  data() {
    return {
      showMapQuery, // 地图搜索
      hotelMapShowConfig, // 存储上浮地图的显示和坐标
      sortType: 2, // 排序类型, 0 推荐排序, 1升序 2降序
      query: {
        city: "",
        cityName: "",
        arrivalDate: "",
        departureDate: "",
        bookingDate: [],
        keywords: {},
        lat: "",
        lon: "",
        landmarkSearch: "001"
      },
      totalDay: 0, // 统计总共选择了多少天
      hotelDataList: [],
      pickerOptions: {
        disabledDate: (time) => time.getTime() < new Date().getTime() - 8.64e7
      },
      seachOptions: [],
      resourceList: [],
      loading: false,
      isRequestListEnd: false,
      isFilterRequestEnd: true
    };
  },
  computed: {
    ...mapGetters("businessTravel", {
      cacheHotelInfo: "hotelInfo"
    })
  },
  watch: {
    hotelDataSource() {
      if (this.hotelDataSource?.records) {
        this.showMapQuery.hotelDataSource.records = this.hotelDataSource.records;
        this.showMapQuery.hotelDataSource.arrivalDate = this.query.arrivalDate;
        this.showMapQuery.hotelDataSource.departureDate = this.query.departureDate;
      }
    },
    "query.bookingDate": {
      handler(value) {
        if (value && value.length) {
          const date1 = dayjs(value[0]);
          let date2 = dayjs(value[1]);
          if (dayjs(date1).isSame(dayjs(date2))) {
            date2 = dayjs(new Date(date2).getTime() + 8.64e7);
            this.query.bookingDate = [date1, date2];
          }
          this.query.arrivalDate = dayjs(date1).format("YYYY-MM-DD");
          this.query.departureDate = dayjs(date2).format("YYYY-MM-DD");
          this.bookData.arrivalDate = this.query.arrivalDate;
          this.bookData.departureDate = this.query.departureDate;
          this.totalDay = date2.diff(date1, "day");
        } else {
          this.totalDay = 0;
        }
      }
    }
  },
  mixins: [hotelData],
  created() {

  },
  activated() {
    this.loadCacheDatas();
    this.bookData = { ...this.query };
    this.bookData.pageSize = 10;
    this.bookData.pageNo = 1;
    this.requestHotelDataList();
    this.requestPositionBrand();
  },
  methods: {
    ...mapMutations("businessTravel", [
      "setHotelInfo"
    ]),
    // 请求酒店列表数据
    @request(false)
    async requestHotelDataList() {
      this.isRequestListEnd = false;
      await this.requestTripHotelList();
      this.isRequestListEnd = true;
    },
    cityInfoChanged(info) {
      let needClear = false;
      if (this.query.city !== info.cityCode) {
        needClear = true;
      }
      this.query.cityInfo = info;
      this.query.cityName = info.enCityName;
      this.query.city = info.cityCode;
      this.bookData.cityName = this.query.cityName;
      this.bookData.city = this.query.city;
      this.clearLocationData();
      this.comimitHotelInfo();
      if (needClear) {
        this.$refs.hotelChooseCard.clearAllOption();
      }
    },
    clearLocationData() {
      this.query.lat = null;
      this.query.lon = null;
      this.query.landmarkSearch = null;
      this.query.keywords = {};
      this.bookData.lat = null;
      this.bookData.lon = null;
      this.bookData.landmarkSearch = null;
      this.bookData.keywords = {};
      this.seachOptions = [];
    },
    comimitHotelInfo() {
      this.setHotelInfo(cloneDeep(this.query));
    },
    // 清页码,并请求酒店数据
    async clearAndRequestDataList() {
      this.bookData.pageSize = 10;
      this.bookData.pageNo = 1;
      this.isFilterRequestEnd = false;
      await this.requestTripHotelList();
      this.isFilterRequestEnd = true;
    },
    // 请求区域商圈,酒店品牌数据
    async requestPositionBrand() {
      await this.requestGetPosition();
      await this.requestGetBrand();
    },
    onChange(val) {
      this.query.keywords = val;
      if (val) {
        this.bookData.lat = val?.lat;
        this.bookData.lon = val?.lon;
        this.bookData.landmarkSearch = "000";
      } else {
        this.bookData.landmarkSearch = "001";
      }
    },
    // 筛选数据改变
    filterChangeParams(params) {
      this.bookData.position = params.position;
      this.bookData.price = params.price;
      this.bookData.category = params.category;
      this.bookData.brand = params.brand;
      this.clearAndRequestDataList();
    },
    // 翻页数据改变
    changePage(params) {
      this.bookData.pageSize = params.pageSize;
      this.bookData.pageNo = params.pageNo;
      this.requestTripHotelList();
    },
    // 加载缓存数据
    loadCacheDatas() {
      const obj = this.cacheHotelInfo;
      if (obj) {
        this.query.city = obj.city;
        this.query.cityName = obj.cityName;
        this.query.arrivalDate = dayjs(obj.arrivalDate).format("YYYY-MM-DD");
        this.query.departureDate = dayjs(obj.departureDate).format("YYYY-MM-DD");
        this.query.cityInfo = obj.cityInfo;
        this.query.bookingDate = [
          new Date(obj.arrivalDate),
          new Date(obj.departureDate)
        ];
        this.query.keywords = obj.keywords;
        if (obj?.landmarkSearch === "000") {
          this.query.lat = obj.keywords?.lat;
          this.query.lon = obj.keywords?.lon;
          this.query.landmarkSearch = "000";
        }
        this.seachOptions = [...obj.seachOptions];
      }
    },
    // 地标模糊搜索
    async remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        const list = await this.requestLocationTips(query);
        if (list?.length > 0) {
          this.seachOptions = [];
          for (let i = 0; i < list.length; i++) {
            if (list[i]?.lat && list[i]?.lon) {
              this.seachOptions.push({
                value: list[i]?.name,
                label: list[i]?.name,
                address: list[i]?.address,
                district: list[i]?.district,
                lat: list[i]?.lat,
                lon: list[i]?.lon,
                name: list[i]?.name
              });
            }
          }
        } else {
          this.seachOptions = [];
        }
        this.loading = false;
      } else {
        this.seachOptions = [];
      }
    },
    searchClick() {
      this.requestHotelDataList();
    },
    gotoMap() {
      this.$router.push("/business-travel/hotel/map-query");
    },
    toBookingPage(val) {
      const hotelInfo = cloneDeep(val);
      hotelInfo.arrivalDate = this.bookData.arrivalDate;
      hotelInfo.departureDate = this.bookData.departureDate;
      hotelInfo.tripId = this.$route.query?.tripId;
      this.$router.push({
        path: "/business-travel/hotel/booking",
        query: hotelInfo
      });
    },
    recommendSort() {
      // 推荐排序按钮
      this.sortType = 0;
      this.bookData.sortFields = "";
      this.bookData.sortRank = "";
      this.clearAndRequestDataList();
    },
    sortPriceUp() {
      // 价格按低到高排序
      this.sortType = 1;
      this.bookData.sortFields = "price";
      this.bookData.sortRank = "asc";
      this.clearAndRequestDataList();
    },
    sortPriceDown() {
      // 价格按高到低排序
      this.sortType = 2;
      this.bookData.sortFields = "price";
      this.bookData.sortRank = "desc";
      this.clearAndRequestDataList();
    }
  }
};
</script>
<style scoped lang="scss">
$minWidth: 950px;

.query-content-container {
  height: 100%;
  width: 100%;
  background: #FFFFFF;
  // min-width: 960px;
  .date-content-contain {
    overflow: auto;
    width: 100%;
    height: calc(100% - 55px);
    border-bottom: 1px solid rgba(232, 236, 242, 1);
  }
  .page-container {
    height: 55px;
  }
  .query-area {
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    /deep/ .en-city-panel {
      left: -2px;
    }

    .query-title {
      height: 60px;
      background: rgba(234, 240, 245, 1);
      border-radius: 4px 4px 0 0;
      padding: 14px 20px 14px 20px;
      text-align: left;
      white-space: nowrap;
      display: flex;
      min-width: $minWidth;
      & /deep/ .el-date-editor.el-input__inner {
        height: 32px;
        line-height: 32px;
      }
      & /deep/ .el-input__icon {
        height: 32px;
      }
      & /deep/ .el-date-editor .el-range-input {
        font-size: 12px;
      }
      & /deep/ .el-range-separator {
        display: none;
      }
      & /deep/ .el-range-input:first-child {
        text-align: left;
      }
      & /deep/ .el-range-input:last-child {
        text-align: right;
      }
      .query-city {
        max-width: 340px;
        width: 30%;
        display: inline-block;
        vertical-align: middle;
      }
      .query-location-contain {
        position: relative;
        max-width: 340px;
        width: 30%;
        .icon {
          position: absolute;
          left: 20px;
          top: 7px;
        }
        .query-location {
          width: calc(100% - 12px);
          vertical-align: middle;
          margin-left: 12px;
          & /deep/ .el-input__inner {
            padding-left: 30px;
          }
          & /deep/ .el-input__icon {
            line-height: 32px;
          }
        }
      }
      .query-date {
        margin-left: 12px;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        .show-total-day {
          position: absolute;
          left: 47%;
          top: 50%;
          transform: translate(-50%, -50%);
          background: rgba(255, 255, 255, 1);
          border: 1px solid rgba(232, 236, 242, 1);
          border-radius: 10px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(99, 108, 120, 1);
          width: 55px;
          height: 20px;
          line-height: 17px;
          text-align: center;
          &:after {
            content: "";
            background: rgba(232, 236, 242, 1);
            position: absolute;
            right: -9px;
            top: 9px;
            width: 8px;
            height: 1px;
          }
          &:before {
            content: "";
            background: rgba(232, 236, 242, 1);
            position: absolute;
            left: -9px;
            top: 9px;
            width: 8px;
            height: 1px;
          }
        }
      }
      .query-button {
        margin-left: 12px;
        width: 72px;
        height: 32px;
      }
    }
    .query-condition {
      background: rgba(255, 255, 255, 1);
      //border: 1px solid rgba(232, 236, 242, 1);
      border-radius: 4px 4px 0 0;
      min-height: 100px;
      padding: 20px;
      min-width: $minWidth;
    }
  }
  .seaprator-contain {
    background: rgba(29, 37, 50, 1);
    width: 100%;
    min-width: $minWidth;
    .seaprator-top {
      background: rgba(255, 255, 255, 1);
      border-radius: 0 0 4px 4px;
      height: 4px;
      width: 100%;
    }
    .seaprator-bottom {
      margin-top: 8px;
      background: rgba(255, 255, 255, 1);
      border-radius: 4px 4px 0 0;
      height: 4px;
      width: 100%;
    }
  }
  .query-hotel-list {
    background: rgba(255, 255, 255, 1);
    border-radius: 0 0 4px 4px;
    min-width: $minWidth;
    .query-hotel-top-area {
      height: 50px;
      display: flex;
      padding: 0 20px;
      border-bottom: 1px solid rgba(232, 236, 242, 1);
      justify-content: space-between;
      .title {
        font-size: 14px;
        font-family: MicrosoftYaHei;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
        line-height: 30px;
        line-height: 50px;
      }
      .content-area {
        width: 260px;
        display: flex;
        justify-content: space-between;
        line-height: 50px;
        .sorting-button {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: bold;
          color: #333333;
          cursor: pointer;
          -webkit-user-select: none;
          &.active {
            color: rgba(70, 148, 223, 1);
          }
        }
        .price-area {
          display: flex;
          justify-content: center;
          .title {
            font-size: 12px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: rgba(51, 51, 51, 1);
          }
          .icon-area {
            padding: 14px 0;
            margin-left: 5px;
            .block {
              display: block;
              line-height: 10px;
              color: rgba(186, 196, 209, 1);
              cursor: pointer;
              -webkit-user-select: none;
              &.active {
                color: rgba(23, 158, 218, 1);
              }
            }
          }
        }
        .map-button {
          cursor: pointer;
          -webkit-user-select: none;
          display: flex;
          align-items: center;
          .el-icon-map-location {
            color: #179eda;
          }
          .title {
            padding-left: 3px;
            font-size: 12px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: #636c78;
          }
        }
      }
    }
    .query-hotel-list-area {
      height: auto;
      padding: 16px 20px;
      &.loading {
        height: 300px;
      }
    }
  }
}
/deep/ .el-range-input:last-of-type {
  text-align: right;
}
/deep/ .el-range-input:first-of-type {
  text-align: left;
}
.down-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
  transform: translateY(100%);
}

.down-enter {
  transform: translateY(100%);
}
.down-enter-active {
  transition: all 0.4s ease;
}
</style>
<style lang="scss">
// bug37029
.el-date-range-picker__header div{
  font-size:14px;
}
.el-date-table td span{
  border-radius: 5px;
}
.el-date-table td.end-date span, .el-date-table td.start-date span{
  background: #3e90fe;
}
</style>
