var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hotel-container" },
    [
      _vm._v(" " + _vm._s(_vm.a) + " "),
      _c(
        "en-carousel",
        [
          _c(
            "el-form",
            {
              staticClass: "entry",
              attrs: { "label-position": "left", model: _vm.query },
            },
            [
              _c("div", { staticClass: "search-title" }, [
                _vm._v(" 酒店入住 "),
              ]),
              _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "label" }, [_vm._v(" 入住城市 ")]),
                  _c("en-city", {
                    attrs: {
                      value: _vm.query.cityInfo,
                      type: "hotel",
                      placeholder: "请选择城市",
                    },
                    on: { input: _vm.cityInfoChanged },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "label" }, [_vm._v(" 入住日期 ")]),
                  _c("el-date-picker", {
                    attrs: {
                      "prefix-icon": "1",
                      type: "date",
                      placeholder: "选择日期",
                      "picker-options": _vm.pickerInOptions,
                    },
                    on: { change: _vm.arrivalDateChange },
                    model: {
                      value: _vm.query.arrivalDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "arrivalDate", $$v)
                      },
                      expression: "query.arrivalDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "label" }, [_vm._v(" 离店日期 ")]),
                  _c("el-date-picker", {
                    attrs: {
                      "prefix-icon": "1",
                      type: "date",
                      placeholder: "选择日期",
                      "picker-options": _vm.pickerOutOptions,
                    },
                    on: { change: _vm.arrivalDateChange },
                    model: {
                      value: _vm.query.departureDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "departureDate", $$v)
                      },
                      expression: "query.departureDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c("div", { staticClass: "label" }, [_vm._v(" 关键词 ")]),
                  _c(
                    "el-select",
                    {
                      attrs: {
                        "value-key": "name",
                        clearable: true,
                        filterable: "",
                        remote: "",
                        "reserve-keyword": "",
                        placeholder: "酒店名/地标/商圈",
                        "remote-method": _vm.remoteMethod,
                        loading: _vm.loading,
                      },
                      on: { change: _vm.onChange },
                      model: {
                        value: _vm.query.keywords,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "keywords", $$v)
                        },
                        expression: "query.keywords",
                      },
                    },
                    _vm._l(_vm.seachOptions, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.value,
                          attrs: { label: item.label, value: item },
                        },
                        [
                          _c("en-icon", {
                            staticStyle: {
                              fill: "#ff00ff",
                              float: "left",
                              "margin-top": "7px",
                              "margin-right": "5px",
                            },
                            attrs: { name: "ditu-jiudian", size: "16px" },
                          }),
                          _c("span", { staticStyle: { float: "left" } }, [
                            _vm._v(_vm._s(item.label)),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  staticClass: "search-button",
                  attrs: { type: "primary", loading: _vm.buttonLoading },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.search.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" 立即搜索 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }