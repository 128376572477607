var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "person-card-container" }, [
    _vm.type === "addCard"
      ? _c(
          "div",
          { staticClass: "add-new", on: { click: _vm.addPersonCard } },
          [_vm._m(0), _c("div", { staticClass: "right" }, [_vm._v(" 添加 ")])]
        )
      : _c("div", { staticClass: "display-ready" }, [
          _c(
            "div",
            { on: { click: _vm.delPersonCard } },
            [
              _c("en-icon", {
                staticClass: "delete-button",
                staticStyle: { color: "red" },
                attrs: { name: "shibai", size: "16" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "left" },
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    "popper-class": "custom-person-popover",
                    placement: "bottom-start",
                    trigger: "hover",
                  },
                },
                [
                  _c("div", { staticClass: "amount-person-popover" }, [
                    _c("div", { staticClass: "line-container" }, [
                      _c("span", { staticClass: "content" }, [
                        _vm._v("姓名："),
                      ]),
                      _c("span", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.name)),
                      ]),
                    ]),
                    _c("div", { staticClass: "line-container" }, [
                      _c("span", { staticClass: "content" }, [
                        _vm._v("手机号码："),
                      ]),
                      _c("span", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.numDealer(_vm.phoneNum))),
                      ]),
                    ]),
                    _c("div", { staticClass: "line-container" }, [
                      _c("span", { staticClass: "content" }, [
                        _vm._v("身份证："),
                      ]),
                      _c("span", { staticClass: "content" }, [
                        _vm._v(_vm._s(_vm.numDealer(_vm.idCardNum))),
                      ]),
                    ]),
                  ]),
                  _c("en-user-logo", {
                    staticClass: "logo",
                    attrs: {
                      slot: "reference",
                      "user-name": _vm.name,
                      size: "40",
                    },
                    slot: "reference",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "right" }, [
            _c("div", { staticClass: "name" }, [
              _vm._v(" " + _vm._s(_vm.name) + " "),
            ]),
            _c("div", { staticClass: "id-card-num" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm._f("hideCardNo")(
                      _vm.idCardNum,
                      _vm.idCardNum.length == 15 || _vm.idCardNum.length == 18
                        ? 1
                        : 2
                    )
                  ) +
                  " "
              ),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "left" }, [
      _c("i", { staticClass: "el-icon-plus" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }