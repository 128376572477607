<template>
  <div class="person-card-container">
    <div v-if="type === 'addCard'" class="add-new" @click="addPersonCard">
      <div class="left">
        <i class="el-icon-plus"></i>
      </div>
      <div class="right">
        添加
      </div>
    </div>
    <div v-else class="display-ready">
      <div @click="delPersonCard">
        <en-icon
          name="shibai"
          size="16"
          class="delete-button"
          style="color:red"
        >
        </en-icon>
      </div>

      <div class="left">
        <el-popover
          popper-class="custom-person-popover"
          placement="bottom-start"
          trigger="hover"
        >
          <div class="amount-person-popover">
            <div class="line-container">
              <span class="content">姓名：</span>
              <span class="content">{{ name }}</span>
            </div>
            <div class="line-container">
              <span class="content">手机号码：</span>
              <span class="content">{{ numDealer(phoneNum) }}</span>
            </div>
            <div class="line-container">
              <span class="content">身份证：</span>
              <span class="content">{{ numDealer(idCardNum) }}</span>
            </div>
          </div>

          <en-user-logo
            slot="reference"
            :user-name="name"
            class="logo"
            size="40"
          ></en-user-logo>
        </el-popover>

        <!-- <el-tooltip
          class="item"
          effect="dark"
          placement="bottom-start"
        >
          <div
            slot="content"
            style="width:235px"
          >
            <div class="text-line">
              姓名: {{ name }}
            </div>
            <div class="text-line">
              手机号码: {{ numDealer(phoneNum) }}
            </div>
            <div class="text-line">
              身份证: {{ numDealer(idCardNum) }}
            </div>
          </div>
          <en-user-logo
            :user-name="name"
            class="logo"
            size="40"
          ></en-user-logo>
        </el-tooltip> -->
      </div>
      <div class="right">
        <div class="name">
          {{ name }}
        </div>
        <div class="id-card-num">
          {{ idCardNum | hideCardNo((idCardNum.length == 15 || idCardNum.length == 18) ? 1:2) }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { hideCardNo } from "en-js";

export default {
  name: "PersonCard",
  components: {},
  filters: {
    hideCardNo
  },
  props: {
    name: {
      type: String,
      default: "张三"
    },
    phoneNum: {
      type: String,
      default: "18642054479"
    },
    idCardNum: {
      type: String,
      default: "422302198902050421"
    },
    index: {
      type: Number,
      default: 0
    },
    type: {
      type: String,
      default: "showCard" // 分为两种类型 showCard和addCard
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    numDealer(num) {
      // 处理数字,除前三位和后四位其他均隐藏
      const { length } = num;
      const numList = num.split("");
      if (length <= 7) {
        numList.splice(0, length - 4, ...Array(length - 4).fill("*"));
      } else {
        numList.splice(3, length - 7, ...Array(length - 7).fill("*"));
      }
      return numList.join("");
    },
    delPersonCard() {
      this.$emit("deletePersonCard", this.index);
      // console.log(`delPersonCard${this.index}`);
    },
    addPersonCard() {
      this.$emit("add");
    }
  }
};
</script>
<style scoped lang="scss">
.person-card-container {
  width: 280px;
  height: 80px;
  background: rgba(255, 255, 255, 1);
  border: 1px solid rgba(232, 236, 242, 0.98);
  border-radius: 4px;
  position: relative;

  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  }
  .display-ready {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 13px;
    .delete-button {
      display: none;
    }
    &:hover {
      .delete-button {
        display: block;
        position: absolute;
        top: -10px;
        right: -10px;
      }
    }
    .left {
      height: 40px;
      width: 40px;
      border-radius: 50%;
      overflow: hidden;
      .logo {
        height: 40px;
        width: 40px;
      }
    }

    .right {
      text-align: left;
      margin-left: 14px;
      .name {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(99, 108, 120, 1);
      }
      .id-card-num {
        font-size: 12px;
        font-family: MicrosoftYaHei;
        font-weight: 400;
        color: rgba(145, 161, 183, 1);
        margin-top: 11px;
      }
    }
  }

  .add-new {
    height: 100%;
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    padding: 20px 13px;
    .left {
      width: 40px;
      height: 40px;
      background: rgba(255, 255, 255, 1);
      border: 1px solid rgba(233, 233, 233, 1);
      border-radius: 50%;
      cursor: pointer;
      .el-icon-plus {
        line-height: 38px;
        color: #3e90fe;
        font-weight: bold;
      }
    }
    .right {
      -webkit-user-select: none;
      text-align: left;
      margin-left: 14px;
      font-size: 12px;
      font-family: MicrosoftYaHei;
      font-weight: 400;
      color: rgba(70, 148, 223, 1);
    }
  }
}
.text-line {
  font-size: 12px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 20px;
}
</style>

<style lang="scss">
.el-popper[x-placement^="bottom"] {
  .popper__arrow {
    &::after {
      top: 0 !important;
    }
  }
}
.el-popper[x-placement^="top"] {
  .popper__arrow {
    &::after {
      bottom: 0 !important;
    }
  }
}
.custom-person-popover {
  .popper__arrow {
    &::after {
      // border-top-color: orange !important;
      // border-bottom-color: orange !important;
      border-top-color: #666975 !important;
      border-bottom-color: #666975 !important;
    }
  }
  .amount-person-popover {
    margin: -13px;
    padding: 12px;
    // background-color: orange;
    background-color: #666975;
    border: 0 solid #fff;
    border-radius: 4px;
    height: 74px;
    min-width: 200px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #fff;
    .line-container {
      display: flex;
    }
    .content {
      display: block;
      padding-bottom: 2px;
      color: #fff;
    }
  }
}
</style>
