<template>
  <div v-en-loading="enLoading" class="hotel-show-card">
    <div class="card">
      <en-image
        :src="pics[0]"
        fit="cover"
        class="img"
        lazy
        :preview-src-list="pics"
      ></en-image>
      <div class="content">
        <div class="left">
          <div class="title">
            {{ hotelName }}
          </div>
          <div class="label">
            {{ label }}
          </div>

          <el-popover
            v-model="showAllAddress"
            popper-class="custom-address-popover"
            placement="bottom-end"
            trigger="manual"
          >
            <div class="address-popover">
              {{ address }}
            </div>
            <div
              slot="reference"
              ref="addressLocation"
              class="location"
              @mouseover="showAllAddressDetail"
              @mouseleave="hiddenAllAddressDetail"
            >
              {{ address }}
            </div>
          </el-popover>
          <div class="map-area" @click="showMap">
            <div class="icon el-icon-location"></div>
            <div class="title">
              查看地图
            </div>
          </div>
        </div>
        <div class="right">
          <div v-if="!isFull" class="price">
            ￥ <span style="font-size:18px">{{ price }}</span> 起
          </div>
          <en-button
            v-if="!isFull && !showDetailRoomList"
            class="button"
            @click="showDetail"
          >
            预订
          </en-button>
          <div
            v-if="!isFull && showDetailRoomList"
            class="button pick-up"
            @click="pickUpDetail"
          >
            收起
          </div>
          <div v-if="isFull" class="button full">
            已满房
          </div>
        </div>
      </div>
    </div>
    <en-collapse-area>
      <hotelDetail
        v-if="showDetailRoomList"
        :room-info="roomInfo"
        :hotel-info="hotelInfo"
        class="hotel-detail-container"
        @toBookingPage="toBookingPage"
      ></hotelDetail>
    </en-collapse-area>
  </div>
</template>
<script>
import { request } from "en-js";
import hotelDetail from "./hotel-detail-min";
import showConfig from "../data/showDetailMap";
import hotelData from "../hotelData";

export default {
  name: "",
  components: { hotelDetail },
  props: {
    hotelInfo: {
      type: Object,
      default: () => ({})
    },
    isFull: {
      type: Boolean,
      default: false
    },
    price: {
      type: Number,
      default: 0
    },
    hotelName: {
      type: String,
      default: "北京鹏润国际大酒店"
    },
    label: {
      type: String,
      default: "舒适性酒店"
    },
    address: {
      type: String,
      default: "位于亮马桥区域，朝阳区区霄云路26号，靠近新恒基国际大厦"
    },
    hotelId: {
      type: String,
      default: ""
    },
    arrivalDate: {
      type: String,
      default: "2020-01-01"
    },
    departureDate: {
      type: String,
      default: "2020-01-02"
    },
    pics: {
      type: Array,
      default: () => [
        "http://cdn.enfry.com/hotel_test_need_delete.png",
        "http://cdn.enfry.com/hotel_test_need_delete.png"
      ]
    }
  },
  data() {
    return {
      showConfig,
      showDetailRoomList: false,
      showAllAddress: false,
      enLoading: false,
      roomInfo: []
    };
  },
  mixins: [hotelData],
  computed: {
    photo() {
      return `url(${this.pic})`;
    }
  },
  methods: {
    showMap() {
      // 点击地图图标显示地图
      this.showConfig.show = true;
    },
    @request(true, "enLoading")
    async showDetail() {
      // 点击card的预定按钮显示详细信息
      const roomInfo = await this.requestHotelRatePlan({
        hotelId: this.hotelId,
        arrivalDate: this.arrivalDate,
        departureDate: this.departureDate
      });
      this.roomInfo = [...roomInfo];
      this.showDetailRoomList = true;
    },
    pickUpDetail() {
      this.showDetailRoomList = false;
    },
    showAllAddressDetail() {
      const el = this.$refs.addressLocation;
      this.showAllAddress = el.clientWidth < el.scrollWidth;
    },
    hiddenAllAddressDetail() {
      this.showAllAddress = false;
    },
    toBookingPage(val) {
      this.$emit("toBookingPage", val);
    }
  }
};
</script>
<style scoped lang="scss">
.hotel-show-card {
  transition: height 1s;
  .card {
    height: 100px;
    margin-bottom: 16px;
    border: 1px solid rgba(232, 236, 242, 1);
    border-radius: 4px;
    display: flex;
    position: relative;
    overflow: hidden;
    .img {
      height: 100px;
      width: 100px;
      background: #e9ecf2;
    }
    .content {
      width: calc(100% - 100px);
      display: flex;
      padding: 10px;
      text-align: left;
      .left {
        width: calc(100% - 90px);
        .title {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          overflow: hidden; /*超出部分隐藏*/
          white-space: nowrap; /*不换行*/
          text-overflow: ellipsis; /*超出部分文字以...显示*/
        }
        .label {
          height: 24px;
          background: #fefaef;
          margin-top: 10px;
          display: inline-block;
          padding: 0 8px;
          border-radius: 4px;
          line-height: 24px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(247, 191, 39, 1);
        }
        .location {
          overflow: hidden; /*超出部分隐藏*/
          white-space: nowrap; /*不换行*/
          text-overflow: ellipsis; /*超出部分文字以...显示*/
          margin-top: 10px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          font-weight: 400;
          color: rgba(145, 161, 183, 1);
        }
        .map-area {
          margin-top: 43px;
          display: flex;
          align-items: center;
          cursor: pointer;
          -webkit-user-select: none;
          .icon {
            color: #3e90fe;
          }
          .title {
            font-size: 12px;
            font-family: MicrosoftYaHei;
            font-weight: 400;
            color: rgba(145, 161, 183, 1);
          }
        }
      }
      .right {
        width: 90px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .price {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          font-weight: bold;
          color: rgba(247, 133, 40, 1);
          margin-bottom: 16px;
        }
        /deep/ .button.en-btn-normal {
          width: 80px;
          line-height: 14px;
        }
        .button {
          width: 72px;
          height: 32px;
          text-align: center;
          font-size: 12px;
          line-height: 32px;
          border-radius: 4px;
          -webkit-user-select: none;
          cursor: pointer;
          &.full {
            background: #f0f1f2;
            color: rgba(99, 108, 120, 1);
          }
          &.pick-up {
            color: #3e90fe;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(97, 164, 227, 1);
            line-height: 32px;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.el-popper[x-placement^="bottom"] {
  .popper__arrow {
    &::after {
      top: 0 !important;
    }
  }
}
.el-popper[x-placement^="top"] {
  .popper__arrow {
    &::after {
      bottom: 0 !important;
    }
  }
}
.custom-address-popover {
  .popper__arrow {
    &::after {
      border-top-color: #666975 !important;
      border-bottom-color: #666975 !important;
    }
  }
  .address-popover {
    margin: -13px;
    padding: 12px;
    background-color: #666975;
    border: 0 solid #fff;
    border-radius: 4px;
    height: auto;
    width: 300px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #fff;
  }
}
</style>
