var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "map-query-container" }, [
    _c("div", { staticClass: "title-area" }, [
      _c(
        "div",
        { staticClass: "icon", on: { click: _vm.goBack } },
        [_c("en-icon", { attrs: { name: "iconfanhui", size: "20px" } })],
        1
      ),
      _c("div", { staticClass: "title" }, [_vm._v(" 地图查询 ")]),
    ]),
    _c("div", { staticClass: "content-area" }, [_c("mapComp")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }